import React from 'react';

function SoSolarTaxCredit(props) {
    return (
        <div>
            <div className="container">
                <div className="page-header ">

                    <h1>Solar Tax Credit Savings</h1>


                </div>
            </div>

            <div className="container container-page">


                <div className="row" itemProp="makesOffer" itemScope itemType="http://schema.org/Offer" style={{display: "flex"}}>
                    <div className="col-md-6 format-page" style={{width: "50%"}}>
                        <div className="offer-hero">
                            <img itemProp="image" className="img-responsive"
                                 src="/assets/media/pictures/wwwsolarmaxtechcom/offers/images/large/cf19a7db-a0b6-44b1-bfee-a8941bb33dbd.png"
                                 alt="Federal Solar Investment Tax Credit"/>
                        </div>

                        <div className="page-content"><p>There can be a considerable amount of confusion and
                            misunderstanding regarding
                            the Federal Solar Investment Tax Credit. While the concept seems simple, <strong>it may be
                                complicated
                                because everyone’s tax situation is different</strong>. Since there is no universal
                            answer that
                            applies to all solar owners let’s explore your options.</p>
                            <p><strong>Disclaimer:</strong> Keep in mind that we, at SolarMax Technology, are not tax
                                professionals.
                                We are not qualified to provide homeowners with tax advice. Always seek the advice of a
                                tax
                                professional to determine how to best utilize any federal solar investment tax credit
                                that you may
                                be eligible to receive. </p>
                            <h2>Contents </h2>
                            <h3><p><a href="#About_the_federal">About the federal solar tax credit</a></p></h3>
                            <ul>
                                <li><a href="#eligibility">Eligibility for the solar tax credit</a></li>
                                <li><a href="#residential">Residential qualifications</a></li>
                                <li><a href="#businessqual">Business qualifications</a></li>
                                <li><a href="#taxcredit">What does the tax credit cover?</a></li>
                                <li><a href="#byyear">Tax credit rates by year</a></li>
                            </ul>
                            <h3><a href="#howto">How to use your tax credit</a></h3>
                            <ul>
                                <li><a href="#whatyear">What year is my solar tax credit valid for?</a></li>
                                <li><a href="#isnot">What the solar energy tax credit is NOT:</a></li>
                                <li><a href="#whatis">What exactly is the federal solar tax credit?</a></li>
                                <li><a href="#howmuch">Is there an easy way to tell how much of the federal solar tax
                                    credit I can
                                    use?</a></li>
                            </ul>
                            <h3><a href="#howto">How to file and claim your tax credit</a></h3>
                            <ul>
                                <li><a href="#complete">How to complete Form 5695</a></li>
                            </ul>
                            <h3><a href="#estimate">How to estimate your solar tax credit return for this year</a></h3>
                            <ul>
                                <li><a href="#easyway">Is there an easy way to tell how much of the tax credit I can
                                    use?</a></li>
                                <li><a href="#download">Download the Federal Solar Tax Credit Guide</a></li>
                                <li><a href="#help">Need further assistance from a qualified renewable energy expert</a>?
                                </li>
                            </ul>
                            <h2>About the federal solar tax credit</h2>
                            <div className="se-component se-image-container __se__float-none">
                                <figure ><img src="../../img/page/tax-image-coin.png"
                                                                  className="pull-left img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive"
                                                                  alt=""/></figure>
                            </div>
                            <p>The federal solar tax credit is a tax credit that you can receive when filing your annual
                                tax return.
                                <strong>The tax credit equals 30% of the total cost of your home solar energy
                                    system</strong>. For
                                example, if your solar energy system costs you a total of $20,000 then you can expect to
                                apply
                                $5,200 in credit to your tax return.</p>
                            <p><strong>There is no limit to the amount you can claim on this credit</strong>. Whether
                                your solar
                                system costs $10,000 or $10,000,000, if you meet the eligibility criteria, you can claim
                                your 30%
                                tax credit on this amount.</p>
                            <h3>Eligibility for the solar tax credit</h3>
                            <p>Before you can claim the federal solar tax credit you need to meet the eligibility
                                requirements like
                                most credits or rebates. The requirements are different depending on whether you are
                                installing
                                solar onto your residence or your business.</p>
                            <h3>Residential qualifications</h3>
                            <ul>
                                <li>Your solar energy system <strong>must be providing energy to your home</strong>, be
                                    approved and
                                    in service.
                                </li>
                                <li>All local and utility <strong>fire and electrical code rules must be met</strong>.
                                </li>
                                <li>Your system must be installed and operating <strong>after January 1, 2006 and on, or
                                    before,
                                    December 31, 2021</strong>.
                                </li>
                                <li><strong>You must own your system by purchasing or financing it</strong>. A lease or
                                    power
                                    purchase agreement (PPA) does not qualify you for the tax credit.
                                </li>
                            </ul>
                            <h3><span id="businessqual">Business qualifications</span></h3>
                            <ul>
                                <li>Your system must be installed and operating <strong>after January 1, 2006 and on, or
                                    before,
                                    December 31, 2021</strong>.
                                </li>
                                <li>Your solar energy system <strong>equipment must be new, not used</strong>.</li>
                                <li>Your solar energy system must be <strong>used by someone that is subject to U.S.
                                    income
                                    taxes</strong>. With the exclusion of tax-exempt organizations like churches,
                                    charities or
                                    schools.
                                </li>
                                <li>Your solar energy system must be <strong>located in the United States</strong>.</li>
                                <li>The electricity produced <strong>can’t be used to heat a swimming pool</strong>.
                                </li>
                            </ul>
                            <h3><span id="taxcredit">What does the Tax Credit cover?</span></h3>
                            <p>The federal solar tax credit is <strong>based on the entire cost</strong> of your solar
                                energy system
                                including the costs of:</p>
                            <ul>
                                <li>The solar panels, inverters and all materials</li>
                                <li>All labor installation charges</li>
                                <li>Roof repairs necessary to install solar (not the entire roof, just the side(s) that
                                    the solar
                                    panels are installed on)
                                </li>
                                <li>A home battery if it is charged solely by the solar panels</li>
                                <li>Any upgrades or home modifications required to be compliant with local code such as
                                    main service
                                    panel upgrades, trenching, relocation of meters or other appliances.
                                </li>
                            </ul>
                            <h3><span id="byyear">Tax credit rates by year</span></h3>
                            <p>As you can see the tax credit goes down in 2022 and 2023 until it expires completely for
                                residential
                                installation in 2024. Businesses can still take advantage of a 10% credit from 2024 and
                                on.</p>
                            <div>
                                <table className="table-striped table">
                                    <tbody>
                                    <tr>
                                        <td><p><strong>Year</strong></p></td>
                                        <td><p><strong>Tax Credit Amount</strong></p></td>
                                    </tr>
                                    <tr>
                                        <td><p>2006 to 2019</p></td>
                                        <td><p>30%</p></td>
                                    </tr>
                                    <tr>
                                        <td><p>2020 to 2022</p></td>
                                        <td><p>26%</p></td>
                                    </tr>
                                    <tr>
                                        <td><p>2022 to 2032</p></td>
                                        <td><p>30% <br/>(Inflation Reduction Act)</p></td>
                                    </tr>
                                    {/*<tr>*/}
                                    {/*    <td><p>2024 and on</p></td>*/}
                                    {/*    <td><p>0%<br/> (10% for businesses)</p></td>*/}
                                    {/*</tr>*/}
                                    </tbody>
                                </table>
                            </div>
                            <h2><span id="howto">How to use your tax credit</span></h2>
                            <div className="se-component se-image-container __se__float- __se__float-none">
                                <figure ><img src="../../img/page/tax-image-energy.png"
                                                                  className="pull-left img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive"
                                                                  alt="" /></figure>
                            </div>
                            <h3><span id="whatyear">What year is my solar tax credit valid for?</span></h3>
                            <p>The IRS says that you can claim your solar tax credit on the date your system
                                installation is
                                complete and becomes active.</p>
                            <p>When you get solar installed on your home, an inspector from the utility company comes
                                out for the
                                final inspection and will give you Permission to Operate (PTO). <strong>The date you
                                    receive PTO is
                                    the date your system’s installation is complete</strong> and you use this date to
                                determine
                                which year you can begin to claim your solar tax credit in.</p>
                            <p><strong>Your tax credit can be carried forward to the next year.</strong> So if you don’t
                                use 100%
                                the first year, IRS Form 5695 states you can use the remaining balance the next year.
                                However, it is
                                not yet clear whether you can carry an unused solar tax credit to years after the solar
                                credit
                                expires in 2022.</p>
                            <h3><span id="isnot">What the solar energy tax credit is NOT:</span></h3>
                            <ul>
                                <li>It <strong>is not a rebate</strong></li>
                                <li>It <strong>is not a check</strong></li>
                                <li>It <strong>is not an itemized deduction</strong></li>
                            </ul>
                            <h3><span id="whatis">What exactly is the federal solar tax credit?</span></h3>
                            <p>The federal solar tax credit is a credit that can be used to offset your federal income
                                tax
                                liability.</p>
                            <p><strong>This means that you must owe taxes in order to utilize the tax credit.</strong>
                            </p>
                            <ul>
                                <li>Having a tax liability does not mean that you have to write a check when you submit
                                    your tax
                                    return. It means that the IRS keeps some of the income tax that was withheld or
                                    paid.
                                </li>
                                <li>Getting a refund does not mean that you do not owe taxes. It means that more taxes
                                    were
                                    collected by the IRS than what you ended up owing.
                                </li>
                                <li>Not having to write a check to the IRS when you submit your tax return to the IRS
                                    does not mean
                                    that you do not owe taxes.
                                </li>
                            </ul>
                            <p>The amount of any federal solar tax credit that is used cannot exceed the total tax
                                liability for
                                that year.</p>
                            <ul>
                                <li>In other words, you cannot use a credit for more than what you owe.</li>
                                <li>You may be able to use some of your tax credit now, and some later.</li>
                                <li>You may be able to carry over the tax credit to another year and use it when you
                                    have a tax
                                    liability in a future year.
                                </li>
                            </ul>
                            <h2><span id="howto">How to file and claim your federal solar investment tax credit</span>
                            </h2>
                            <div className="se-component se-image-container __se__float- __se__float-none">
                                <figure ><img src="../../img/page/tax-image-calc.png"
                                                                  className="pull-left img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive"
                                                                  alt=""/></figure>
                            </div>
                            <p>As mentioned before, the SolarMax Technology staff are not tax professionals. We are not
                                qualified to
                                provide homeowners with tax advice. So always seek the advice of a tax professional to
                                determine how
                                to best utilize any federal solar tax credit that you may be eligible to receive.</p>
                            <p>The IRS provides instructions for filling out Form 5695 required for claiming the tax
                                credit: <a
                                    href="https://www.irs.gov/pub/irs-pdf/i5695.pdf" rel="nofollow" target="_blank">2018
                                    Instructions for Form 5695</a></p>
                            <h3><span id="complete">How to complete Form 5695</span></h3>
                            <p><a href="https://www.irs.gov/pub/irs-pdf/f5695.pdf" rel="nofollow" target="_blank">IRS
                                Form 5695</a>
                                calculates tax credits for a variety of residential energy improvements including solar.
                                You only
                                need to focus on line 1 which is for solar electricity. </p>
                            <ul>
                                <li>Line 1: Start by putting the total of all your solar energy system costs into line
                                    1.
                                </li>
                                <li>Line 5: Add lines 1 through 4 and put the total into line 5.</li>
                                <li>Line 6: Multiply line 5 by 26% (.26) and enter the number into line 6.</li>
                            </ul>
                            <p>The amount in Line 6 is your tax credit.</p>
                            <p><strong>Example:</strong> Using a total system cost of $20,000</p>
                            <table className="table-striped table">
                                <tbody>
                                <tr>
                                    <td><p>1</p></td>
                                    <td><p>Qualified solar electric property costs</p></td>
                                    <td><p>1</p></td>
                                    <td><p>$20,000</p></td>
                                </tr>
                                <tr>
                                    <td><p>2</p></td>
                                    <td><p>Qualified solar water heating property costs</p></td>
                                    <td><p>2</p></td>
                                    <td><p>0</p></td>
                                </tr>
                                <tr>
                                    <td><p>3</p></td>
                                    <td><p>Qualified small wind energy heating property costs</p></td>
                                    <td><p>3</p></td>
                                    <td><p>0</p></td>
                                </tr>
                                <tr>
                                    <td><p>4</p></td>
                                    <td><p>Qualified geothermal heat pump property costs</p></td>
                                    <td><p>4</p></td>
                                    <td><p>0</p></td>
                                </tr>
                                <tr>
                                    <td><p>5</p></td>
                                    <td><p>Add lines 1 through 4</p></td>
                                    <td><p>5</p></td>
                                    <td><p>$ 20,000 </p></td>
                                </tr>
                                <tr>
                                    <td><p>6</p></td>
                                    <td><p>Multiply line 5 by 26% (.26)</p></td>
                                    <td><p>6</p></td>
                                    <td><p>$ 5,200 </p></td>
                                </tr>
                                </tbody>
                            </table>
                            <h2><span id="estimate">How to estimate your solar tax credit return for this year</span>
                            </h2>
                            <h3><span id="howmuch"><span id="easyway">Is there an easy way to tell how much of the tax credit I can use?</span></span>
                            </h3>
                            <p>No. The easiest way is to consult your tax professional. However, SolarMax Technology has
                                developed
                                the <strong>Tax Credit Impact Estimator </strong>to help customers understand how the
                                tax credit may
                                affect them by showing how it might have affected their taxes in the previous year.</p>
                            <p>If your taxes in the year of installation are going to be the same as last year, then you
                                may get a
                                good idea how the tax credit affects you for this year.</p>
                            <p>Give SolarMax Technology a call or fill out the request form to get started today.</p>
                            <p><br/></p>
                            <p>Your eBook "<strong>Federal Solar Investment Tax Credit: A Practical Guide</strong>" is
                                available for
                                download:</p>
                            <div className="se-component se-image-container __se__float-">
                                <figure><a
                                    href="../assets/media/documents/tax-credit-brochure.pdf"
                                    rel="nofollow"><img
                                    src="../../../cdn2.hubspot.net/hubfs/4146140/ebook2.jpg" alt="ebook2"
                                    className="img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive"
                                    /></a></figure>
                            </div>
                            <p><a href="../assets/media/documents/tax-credit-brochure.pdf" target="_blank"
                                  rel="nofollow"><strong>Download</strong></a><br/>
                            </p>
                            <div className="se-component se-image-container __se__float- __se__float-none">
                                <figure ><a href="../assets/media/documents/tax-credit-brochure.pdf" rel="nofollow"><img
                                    className="img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive"
                                    alt=""/></a></figure>
                            </div>
                            <div className="se-component se-image-container __se__float- __se__float-none">
                                <figure ><a href="../assets/media/documents/tax-credit-brochure.pdf" rel="nofollow"><img
                                    className="img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive img-responsive"
                                    alt=""/></a></figure>
                            </div>
                            <p>Contents:</p>
                            <ul>
                                <li>About the federal solar tax credit</li>
                                <li>What does the tax credit cover?</li>
                                <li>How to use your tax credit</li>
                                <li>How to file and claim your tax credit</li>
                                <li><strong>Bonus material</strong>: the Tax Credit Impact Estimator</li>
                            </ul>
                            <p><br/></p></div>
                    </div>

                    <div className="col-md-6 position-sticky side-bar" style={{width: "50%"}}>

                        <div id="quote-form" className="quote-form panel panel-quote-form">
                            <div className="panel-body">
                                <div className="section-header">
                                    <p className="h2">Get a Free Solar Savings Analysis!</p>
                                    <div className="lead"></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-5 credibility text-center">
                                        <div className="item">
                                            <div className="aggregate-reviews ">
                                                <div className="credibility-description">
                                                    <div className="review-stars">
                                                        <span className="fa fa-star fa-lg"></span> <span
                                                        className="fa fa-star fa-lg"></span> <span
                                                        className="fa fa-star fa-lg"></span>
                                                        <span className="fa fa-star fa-lg"></span> <span
                                                        className="fa fa-star fa-lg fa-star-half-o"></span>
                                                    </div>
                                                </div>

                                                <div className="value">
                                                    <div className="rating">
                                                        <span className="average">4.8</span> out of <span
                                                        className="best">5</span>
                                                    </div>
                                                    based on <span className="votes">45</span> Reviews
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-7">
                                        <iframe className={'form__holder'} style={{width: '100%', height: '670px'}}
                                                id={'solarmax__form'}
                                                src="https://4345356.extforms.netsuite.com/app/site/crm/externalleadpage.nl?compid=4345356&formid=41&h=AAFdikaIVOFmEcL6X0HNZv561ucz6phoJ3CcDdjwDZ5szkC1Eog"
                                                frameBorder="0"></iframe>


                                        <p><small>Your personal information is never distributed or sold to anyone. You
                                            can opt-out
                                            of communications at any time. View our <a
                                                href="../../privacy-policy/index.html">privacy
                                                policy</a>.</small></p>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>


            <div className="breadcrumbs">
                <div className="container">
                    <ol className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <a itemProp="item" href="../../index.html">
                            <span className="hidden-lg">
                                <i className="fas fa-home"></i>
                            </span>
                                <span className="visible-lg" itemProp="name">Home</span>
                            </a>

                        </li>
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <a itemProp="item" href="../index.html">
                                <span itemProp="name">Offers</span>
                            </a>

                        </li>
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <a href="index.html" itemProp="url">
                                <span itemProp="title">Solar Tax Credit Savings</span>
                            </a>

                        </li>

                    </ol>
                </div>
            </div>
        </div>
    );
}

export default SoSolarTaxCredit;
