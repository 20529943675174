import React from 'react';

function VistaCieloHomes(props) {
    return (
        <div>
            <div className="my-3">
                <div className="container">
                    <h1>Vista Cielo Solar Plans</h1>

                </div>
            </div>

            <div className="container">
                <img className="img-responsive mb-3" src="/img/vista-cielo/hero.jpg" alt="Countryside Photo"/>
                <div className="page-content">
                    <p>Your energy efficient Vista Cielo home comes with a solar energy system that produces an
                        abundance of electricity from solar panels on your roof.</p>
                    <h2>Solar Included for $0 Down with a Solar Lease</h2>
                    <p> With a solar lease you don't have to pay the upfront cost of solar panels, equipment, and
                        installation. Instead of paying for a solar system, you pay a fixed monthly amount for the
                        electricity the solar panels generate. It's easy and affordable.</p>
                    <div className="row">
                        <div className="col-sm-6">
                            <ul className="mb-0">
                                <li>Lock in a lower energy cost</li>
                                <li>Production guaranteed</li>
                                <li>Monitoring included</li>
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <ul>
                                <li>Insurance included</li>
                                <li>Maintenance &amp; repairs included</li>
                                <li>Low monthly payments</li>
                            </ul>
                        </div>
                    </div>
                    <h2>Purchase Option</h2>
                    <p>Many home buyers prefer to purchase their solar systems by simply adding the cost to the purchase
                        price of their home. Please review this option with your lender if you plan on financing your
                        home.</p>
                    <div className="row mb-3">
                        <div className="col-sm-6">
                            <ul className="mb-0">
                                <li>Receive a 26% 2019 federal tax credit</li>
                                <li>Lock in a lower energy cost</li>
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <ul>
                                <li>Production guaranteed</li>
                                <li>Monitoring included</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-section bg-lightbluecolor">
                <div className="container page-content">
                    <div className="numbers">
                        1,
                    </div>
                    <div className="row mb-1">
                        <div className="col-md-8">
                            <p><img className="img-responsive" src="/img/vista-cielo/residence1.jpg"
                                    alt="Property 1 Photo"/></p>
                        </div>
                        <div className="col-md-4">
                            <h3>Residence 1 and 1x</h3>
                            <p>
                                System size: 2.95 kW<br/>
                                Solar panels: 10 x Q-Cell 295W<br/>
                                Estimated 1st year production: 5,162 kWh<br/>
                                Lease payment: $73/mo*
                            </p>

                            <p className="fs-14 lh-1-2">* Estimated monthly lease payment. Actual monthly lease payment will
                                vary based on lot, floorplan, elevation, and market rates.</p>
                        </div>
                    </div>
                    <p>Purchase option details:</p>
                    <div className="row">
                        <div className="col-sm-6">
                            <ul>
                                <li>Price per Watt: $3.45</li>
                                <li>Solar savings over 25 years: $63,152.67</li>
                                <li>Purchase amount: $10,177.50</li>
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <ul>
                                <li>Federal tax credit: $3,053.25</li>
                                <li>Net system cost: $7,124.25</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-section">
                <div className="container page-content">
                    <div className="numbers">
                        2,
                    </div>
                    <div className="row mb-1">
                        <div className="col-md-8">
                            <p><img className="img-responsive" src="/img/vista-cielo/residence2.jpg"
                                    alt="Property 2"/></p>
                        </div>
                        <div className="col-md-4">
                            <h3>Residence 2</h3>
                            <p>
                                System size: 3.54 kW<br/>
                                Solar panels: 12 x Q-Cell 295W<br/>
                                Estimated 1st year production: 6,194 kWh<br/>
                                Lease payment: $88/mo*
                            </p>
                            <p className="fs-14 lh-1-2">* Estimated monthly lease payment. Actual monthly lease payment will
                                vary based on lot, floorplan, elevation, and market rates.</p>
                        </div>
                    </div>
                    <p>Purchase option details:</p>
                    <div className="row">
                        <div className="col-sm-6">
                            <ul>
                                <li>Price per Watt: $3.45</li>
                                <li>Solar savings over 25 years: $75,783.21</li>
                                <li>Purchase amount: $12,195.30</li>
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <ul>
                                <li>Federal tax credit: $3,658.59</li>
                                <li>Net system cost: $8,536.71</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-section bg-lightbluecolor mb-3">
                <div className="container page-content">
                    <div className="numbers">3,</div>
                    <div className="row mb-1">
                        <div className="col-md-8">
                            <p><img className="img-responsive" src="/img/vista-cielo/residence3.jpg"
                                    alt="Property 3 Photo"/></p>
                        </div>
                        <div className="col-md-4">
                            <h3>Residence 3</h3>
                            <p>
                                System size: 4.13 kW<br/>
                                Solar panels: 14 x Q-Cell 295W<br/>
                                Estimated 1st year production: 7,199 kWh<br/>
                                Lease payment: $102/mo*
                            </p>

                            <p className="fs-14 lh-1-2">* Estimated monthly lease payment. Actual monthly lease payment will
                                vary based on lot, floorplan, elevation, and market rates.</p>
                        </div>
                    </div>
                    <p>Purchase option details:</p>
                    <div className="row">
                        <div className="col-sm-6">
                            <ul>
                                <li>Price per Watt: $3.45</li>
                                <li>Solar savings over 25 years: $85,340.92</li>
                                <li>Purchase amount: $14,227.80</li>
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <ul>
                                <li>Federal tax credit: $4,268.34</li>
                                <li>Net system cost: $9,959.46</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="mb-2">
                    <p><a href="/pdf/Federal Solar Investment Tax Credit - 2019 SolarMax Guide.pdf" target="_blank">Download
                        the 2019 Federal Solar Tax Credit Guide</a> for more information on the federal tax credit.</p>
                </div>
            </div>

</div>
)
    ;
}

export default VistaCieloHomes;
