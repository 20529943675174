import Navbar from "../includes/navbar";
import {Outlet, useLocation} from "react-router-dom";
import Footer from "../includes/footer";
import {useEffect, useState} from "react";

function HeaderLayout(props) {
    const [showForm, setShowForm] = useState(false)

    const toggleForm = () =>{
        setShowForm(!showForm)
    }

    const location = useLocation();

    const [path, setPath] = useState(location.pathname)

    useEffect(() => {
        props.addPages(location.pathname);
        setPath(location.pathname)
    },[location]);

    return (
        <div className={''}>
            <Navbar path={path} toggleForm={props.toggleForm} browserDetails={props.browserDetails} debugValue={props.debugValue} showForm={props.showForm}/>
            <Outlet toggleForm={toggleForm} showForm={showForm}/>
            <Footer/>
        </div>
    );
}

export default HeaderLayout;