import React, {useState} from 'react';
import '../../assets/css/business-solutions.css'
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import Global from "../shared/global";
import {Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

function BusinessOwnerSolar(props) {
    const [benefitIndex, setBenefitIndex] = useState('0')

    const handleBenefitChange = (e) =>{
        let index = e.target.dataset.benefit_index
        setBenefitIndex(index)
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Businesses - Solar system</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/businesses/solar`}/>
                </Helmet>
            </HelmetProvider>
            <section className="solutions__hero">
            <div className="">
                    <div className="solutions__hero_wrapper">
                        <div className="solutions__hero_pages">
                            <Splide aria-label="" hasTrack={false}
                                    options={{
                                        perPage: 1,
                                        arrows: false,
                                        type: 'loop',
                                        autoplay:true,
                                        resetProgress: false
                                    }}>
                                <SplideTrack>
                                    <SplideSlide style={{backgroundImage: `url('${require('../../assets/media/pictures/static/business/1.1 Hero.jfif')}')`, backgroundSize: `cover`, backgroundPosition:` ${props.browserDetails.u_os === "desktop" ? 'center' : '65%' }`}}>
                                        <div className="slide__wrapper container_s">
                                            <div className="slide__text">
                                                <div className="slide__sub text-heading-6">
                                                    Empowering businesses since 2008
                                                </div>
                                                <div className="slide__title text-heading-1">
                                                    Energize your enterprise
                                                </div>
                                                <div className="slide__desc text-heading-4">
                                                    Save big, become energy-independent, and show off your eco-friendly brand
                                                </div>
                                                <div className="slide__cta text-heading-6">
                                                    <button className=" text-heading-6" onClick={props.toggleForm}>Get a free consultation</button>
                                                </div>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide style={{backgroundImage: `url('${require('../../assets/media/pictures/static/b-battery-hero-1.png')}')`}}>
                                        <div className="slide__wrapper container_s">
                                            <div className="slide__text">
                                                <div className="slide__sub text-heading-6">
                                                    Empowering businesses since 2008
                                                </div>
                                                <div className="slide__title text-heading-1">
                                                    Secure your operations with a dedicated ESS
                                                </div>
                                                <div className="slide__desc text-heading-4">
                                                    Maximize efficiency, reliability, and sustainability with a cutting-edge energy storage systems
                                                </div>
                                                <div className="slide__cta text-heading-6">
                                                    <Link onClick={props.handleLink} to={'/businesses/battery'}>Learn more</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                </SplideTrack>
                                {/*<button className="splide__toggle" type="button">*/}
                                {/*    <span className="splide__toggle__play">Play</span>*/}
                                {/*    <span className="splide__toggle__pause">Pause</span>*/}
                                {/*</button>*/}
                                <div className="splide__progress">
                                    <div className="splide__progress__bar"/>
                                </div>

                            </Splide>
                        </div>
                    </div>
                </div>
            </section>

            <section className="solution__intro_custom">
                <div className="container_s">
                    <div className="solution__intro_wrapper">
                        <div className="solution__intro_text">
                            <div className="solution__intro_title text-heading-4">Unlocking high-powered potential</div>
                            <div className="solution__intro_desc text-body-1">Our advanced commercial solar solutions can transform your operations and bottom line</div>
                        </div>
                        <div className="solution__intro_img">
                            <img src={require('../../assets/media/pictures/static/business/2. Unlocking (Crop).jpg')} alt=""/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="solution__advantages">
                <div className="container_s">
                    <div className="solution__advantages_wrapper">
                        <div className="solution__advantages_text mb-3">
                            <div className="solution__advantages_title text-heading-4 mb-1">Strategic advantages of going solar</div>
                            <div className="solution__advantages_desc text-body-1">From cost savings to corporate responsibility, see how SolarMax can help revolutionize your brand</div>

                        </div>
                        <div className="solution__advantages_elements">
                            <div className="solution__advantages_element">
                                <div className="advantages__element_icon"><i className="ri-line-chart-line"></i></div>
                                <div className="advantages__element_title text-heading-6 mb-1">Drastically reduce operating costs</div>
                                <div className="advantages__element_desc text-body-2">Slash your energy expenses and reinvest the savings into growing your business</div>
                            </div>
                            <div className="solution__advantages_element">
                                <div className="advantages__element_icon"><i className="ri-service-line"></i></div>
                                <div className="advantages__element_title text-heading-6 mb-1">Eco-friendly branding</div>
                                <div className="advantages__element_desc text-body-2">Strengthen your brand and customer loyalty by showing you care about climate change</div>
                            </div>
                            <div className="solution__advantages_element">
                                <div className="advantages__element_icon"><i className="ri-money-dollar-box-line"></i></div>
                                <div className="advantages__element_title text-heading-6 mb-1">Tax incentive advantages</div>
                                <div className="advantages__element_desc text-body-2">Take advantage of significant government incentives and rebates for adopting green technology</div>
                            </div>
                            <div className="solution__advantages_element">
                                <div className="advantages__element_icon"><i className="ri-lightbulb-flash-line"></i></div>
                                <div className="advantages__element_title text-heading-6 mb-1">Total energy independence</div>
                                <div className="advantages__element_desc text-body-2">Ensure uninterrupted power supply and operational resilience with solar and battery systems</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="solutions__benefits_custom">
                <div className="container_s">
                    <div className="solutions__benefits_custom_wrapper">
                        <div className="solutions__benefits_text">
                            <div className="solutions__benefits_title text-heading-4">Streamlined solar integration for your business</div>
                            <div className="solutions__benefits_elements">
                                <div className={'benefits__progress'}></div>
                                <div data-benefit_index={'0'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '0' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'0'} className="benefits__element_num text-heading-6">1.</div>
                                    <div data-benefit_index={'0'} className="benefits__element_text">
                                        <div data-benefit_index={'0'} className="benefits__element_title text-heading-6">Free consultation</div>
                                        <div data-benefit_index={'0'}  className={`text-body-1 ${benefitIndex === '0' ? 'benefits__element_active' : 'benefits__element_hidden'} `}>Our experts analyze your energy needs to design a solar solution that fits your business perfectly.</div>
                                    </div>
                                    <div className={`${benefitIndex === '0' ? 'icon__active' : 'benefits__element_icon'}`}>
                                        <i data-benefit_index={'0'} className="ri-arrow-down-s-line "></i>
                                    </div>
                                </div>
                                <div data-benefit_index={'1'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '1' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'1'} className="benefits__element_num text-heading-6">2.</div>
                                    <div data-benefit_index={'1'} className="benefits__element_text">
                                        <div data-benefit_index={'1'} className="benefits__element_title text-heading-6">Detailed design</div>
                                        <div data-benefit_index={'1'}  className={`text-body-1 ${benefitIndex === '1' ? 'benefits__element_active' : 'benefits__element_hidden'} `}>
                                            We create a custom system using state-of-the-art technology to maximize efficiency and returns.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '1' ? 'icon__active' : 'benefits__element_icon'}`}>
                                        <i data-benefit_index={'1'}  className="ri-arrow-down-s-line"></i>
                                    </div>
                                </div>
                                <div data-benefit_index={'2'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '2' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'2'} className="benefits__element_num text-heading-6">3.</div>
                                    <div data-benefit_index={'2'} className="benefits__element_text">
                                        <div data-benefit_index={'2'} className="benefits__element_title text-heading-6">Hassle-free permitting</div>
                                        <div data-benefit_index={'2'}  className={`text-body-1 ${benefitIndex === '2' ? 'benefits__element_active' : 'benefits__element_hidden'} `}>
                                            We handle all the paperwork, ensuring your system meets all regulations and standards without the headaches.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '2' ? 'icon__active' : 'benefits__element_icon'}`}>
                                        <i data-benefit_index={'2'}  className="ri-arrow-down-s-line"></i>
                                    </div>
                                </div>
                                <div data-benefit_index={'3'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '3' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'3'} className="benefits__element_num text-heading-6">4.</div>
                                    <div data-benefit_index={'3'}className="benefits__element_text">
                                        <div data-benefit_index={'3'} className="benefits__element_title text-heading-6">Expert installation</div>
                                        <div data-benefit_index={'3'}  className={`text-body-1 ${benefitIndex === '3' ? 'benefits__element_active' : 'benefits__element_hidden'} `}>
                                            Our certified installers quickly set up your system without disrupting your operations.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '3' ? 'icon__active' : 'benefits__element_icon'}`}>
                                        <i data-benefit_index={'3'}  className="ri-arrow-down-s-line"></i>
                                    </div>
                                </div>
                                <div data-benefit_index={'4'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '4' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'4'} className="benefits__element_num text-heading-6">5.</div>
                                    <div data-benefit_index={'4'}  className={`benefits__element_text `}>
                                        <div data-benefit_index={'4'} className="benefits__element_title text-heading-6">After-sales service</div>
                                        <div data-benefit_index={'4'}  className={`text-body-1 ${benefitIndex === '4' ? 'benefits__element_active' : 'benefits__element_hidden'}`}>
                                            Enjoy continuous monitoring and dedicated support to keep your system in top performance.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '4' ? 'icon__active' : 'icon__inactive'}`}>
                                        <i data-benefit_index={'4'}  className="ri-arrow-down-s-line"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="solutions__benefits_img">
                            <img src={require(`../../assets/media/pictures/static/business/3.${benefitIndex} BO_Steps (Crop).jpg`)} alt=""/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="roofing__options_cta">
                <div className="container_s">
                    <div className="roofing__options_cta_wrapper">
                        <div className="roofing__options_interact">
                            <div className="roofing__options_text">
                                <div className="roofing__cta_title text-heading-4 mb-2">
                                    Is your business ready for installation?
                                </div>
                                <div className="roofing__cta_desc text-body-1 mb-3">
                                    To maximize your investment, your building must be ready to support your solar system for decades
                                </div>
                                <div className="roofing__cta_cta text-heading-6">
                                    <Link onClick={props.handleLink} to={'/roofing'}>Check our roof assessments</Link>
                                </div>
                            </div>
                           <div className="roofing__options_elements">
                               <div className="roofing__options_element">
                                   <div className="options__element_icon"><i className="ri-checkbox-circle-line"></i></div>
                                   <div className="options__element_text text-body-1">If your roof is more than 15 years old, you might need to renovate it before getting a system</div>
                               </div>
                               <div className="roofing__options_element">
                                   <div className="options__element_icon"><i className="ri-checkbox-circle-line"></i></div>
                                   <div className="options__element_text text-body-1">Our roofing professionals will inspect and renovate your roof to ensure it can outlast your new solar system</div>
                               </div>
                           </div>
                        </div>
                        <div className="roofing__options_img" style={{backgroundImage: `linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 15.46%), linear-gradient(270deg, rgba(255, 255, 255, 0.00) 88.47%, #FFF 100%), url('${require('../../assets/media/pictures/static/business/4. Roofing (Crop).jpg')}')`}}>
                            {/*<img src={require('../../assets/media/pictures/static/business/b-solar-roofing.png')} alt=""/>*/}
                        </div>

                    </div>
                </div>
            </section>

            <section className="why__partner">
                <div className="container">
                    <div className="why__partner__wrapper">
                        <div className="why__partner_title text-heading-4">
                            Why partner with SolarMax
                        </div>
                        <div className="why__partner_elements">
                            <div className="why__partner_element">
                                <div className="partner__element_icon"><i className="ri-user-heart-line"></i></div>
                                <div className="partner__element_title text-heading-5 mb-1">Personalized support at every step</div>
                                <div className="partner__element_desc text-body-1">From consultation to maintenance, our local team is on call and ready to assist</div>
                            </div>
                            <div className="why__partner_element">
                                <div className="partner__element_icon"><i className="ri-medal-line"></i></div>
                                <div className="partner__element_title text-heading-5 mb-1">Only the best for your business</div>
                                <div className="partner__element_desc text-body-1">We use high-performance panels, inverters, and batteries to ensure maximum durability and yield</div>
                            </div>
                            <div className="why__partner_element">
                                <div className="partner__element_icon"><i className="ri-refresh-line"></i></div>
                                <div className="partner__element_title text-heading-5 mb-1">Streamlined supply chain</div>
                                <div className="partner__element_desc text-body-1">Our strong relationships with top distributors mean faster installations and better prices for you</div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <div className="partners">
                <div className="container">
                    <div className="partners__wrapper">
                        <div className="partners__title text-heading-4">PARTNERS</div>
                        <Splide aria-label="" className={'partners__list'} hasTrack={false}
                                options={{
                                    perPage: props.browserDetails.u_os === "desktop" ? 5 : 3,
                                    arrows: false,
                                    type: 'loop',
                                    autoplay:true,
                                    resetProgress: false,
                                    gap: props.browserDetails.u_os === "desktop" ?'32px' : '0px',
                                    pagination: false,
                                    perMove:1,
                                    interval: 2000
                                }}
                        >
                            <SplideTrack>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-1.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-2.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-3.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-4.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-12.jpg')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-6.jpg')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-7.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-8.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-9.jpg')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-10.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-11.jpg')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-5.png')} alt=""/></div>
                                </SplideSlide>

                            </SplideTrack>

                        </Splide>
                        <div className="partners__list">
                        </div>
                    </div>
                </div>
            </div>

            <Global debugValue={props.debugValue}/>
        </>
    );
}

export default BusinessOwnerSolar;