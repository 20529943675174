import React, {useEffect, useState} from "react";
import {Form, Link} from "react-router-dom";
import '../../assets/css/referral.css'
import Faq from "../shared/faq";
import {Helmet, HelmetProvider} from "react-helmet-async";
const ReferralIntro = (props) =>{
    const [referralEmail, setReferralEmail] = React.useState("")
    const [referral, setReferral] = React.useState({
        link: null,
        code: null
    })
    const [error, setError] = useState(null)

    useEffect(() => {
        if (!localStorage.getItem('referral')){

        }else {
            let tmp_data = JSON.parse(localStorage.getItem('referral'))
            setReferral({...referral, link:`http://localhost:3000/my-referral/${tmp_data.rlCode}`, code: tmp_data.rlCode})
        }
    }, []);
    const handleSubmit = (e) =>{
        e.preventDefault()
        let data = {u_id: props.browserDetails.u_id, u_os: props.browserDetails.u_os, u_br: props.browserDetails.u_br, u_data: referralEmail};
        fetch(`${props.debugValue}/api/create-referral`,{
            method: 'POST',
            body: JSON.stringify(data),
            headers: {'Content-Type': 'application/json'}
        }).then(res=>res.json()).then(data=>{
            if(data.status === 200){
                setReferral({...referral, link:`${props.debugValue}/my-referral/${data.code}`, code: data.code})
            }else if(data.status === 204){
                setReferral({...referral, link:`${props.debugValue}/my-referral/${data.code}`, code: data.code})
                localStorage.setItem('referral', JSON.stringify({rlCode: data.code, rlId:props.browserDetails.u_id, rlEmail: referralEmail, rrCode: null}))
            }else{
                console.log('shit')
                setError('Something went wrong, please refresh the page and try again.')
            }
        })
    }

    const changeUser = () =>{
        localStorage.removeItem('referral')
        setReferral({
            link: null,
            code: null
        })
    }

    const handleInput = (e) =>{
        setReferralEmail(e.target.value)
    }

    const copyToClipboard = (e) => {
        let value = e.target.value;
        e.target.select();
        e.target.setSelectionRange(0, 99999); // For mobile devices
        navigator.clipboard.writeText(value).then(()=> console.log('Copied to clipboard'));

    }
    return(
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Referral system</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/referral`}/>
                </Helmet>
            </HelmetProvider>
            <section className="global__hero_single" style={{
                backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url('${require('../../assets/media/pictures/static/financing_hero.jpg')}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="single__hero_wrapper">
                        <div className="single__hero_text">
                            <div className="single__hero_title text-heading-3">Referral</div>
                            <div className="single__hero_desc  text-heading-5">Earn rewards by referring friends to our solar solutions—help us spread the power of clean energy!
                            </div>
                            <div className="single__hero_cta">
                                <a href={'#create__referral'}>Refer a friend</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id={'referral__introduction'} className='referral__introduction'>
                <div className="container_s">
                    <div className="referral__introduction_wrapper">
                        <div className="referral__introduction_text">
                            <div className="referral__introduction_title text-heading-4">
                                Refer a friend and earn Rewards
                            </div>
                            <div className="referral__introduction_desc text-body-1">
                                Our referral program is a great way to share the benefits of our service with your
                                friends and earn rewards for doing so
                            </div>
                        </div>
                        <div className="referral__introduction_elements">
                            {/*<div className="referral__introduction_element">*/}

                            {/*</div>*/}
                            <div className="referral__introduction_element">
                                <div className="introduction__element_img">
                                    <img src={require('../../assets/media/pictures/static/referrer-1.png')} alt=""/>
                                </div>
                                <div className="introduction__element_text">
                                    <div className="introduction__element_title text-heading-5">Process</div>
                                    <div className="introduction__element_desc">
                                        <div className="element__desc_single">
                                            <i className="ri-checkbox-circle-line"></i>
                                            <span className="text-body-1">
                                                Sign up for our referral program
                                            </span>
                                        </div>
                                        <div className="element__desc_single">
                                            <i className="ri-checkbox-circle-line"></i>
                                            <span className="text-body-1">
                                                Share your unique referral link with friends
                                            </span>
                                        </div>
                                        <div className="element__desc_single">
                                            <i className="ri-checkbox-circle-line"></i>
                                            <span className="text-body-1">
                                                Earn rewards when your friends make a purchase
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="referral__introduction_element">
                                <div className="introduction__element_img">
                                    <img src={require('../../assets/media/pictures/static/referrer-2.png')} alt=""/>
                                </div>
                                <div className="introduction__element_text">
                                    <div className="introduction__element_title text-heading-5">Benefits</div>
                                    <div className="introduction__element_desc text-body-1">
                                        Earn $10 for every friend you refer, and your friends get a 5% discount on their
                                        first purchase!
                                    </div>
                                </div>
                            </div>
                            <div className="referral__introduction_element">
                                <div className="introduction__element_img">
                                    <img src={require('../../assets/media/pictures/static/referrer-3.png')} alt=""/>
                                </div>
                                <div className="introduction__element_text">
                                    <div className="introduction__element_title text-heading-5">Why Refer ?</div>
                                    <div className="introduction__element_desc text-body-1">
                                        Share the love for our brand and help your friends enjoy our products while you
                                        earn rewards
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='referral__introduction_alt'>
                <div className="container_s">
                    <div className="referral__introduction_wrapper">
                        <div className="referral__introduction_text">
                            <div className="referral__introduction_title text-heading-4">
                                Redeeming Referral
                            </div>

                        </div>
                        <div className="referral__introduction_elements">
                            {/*<div className="referral__introduction_element">*/}

                            {/*</div>*/}
                            <div className="referral__introduction_element">
                                <div className="introduction__element_img">
                                    <img src={require('../../assets/media/pictures/static/referred-1.png')} alt=""/>
                                </div>
                                <div className="introduction__element_text">
                                    <div className="introduction__element_title text-heading-5">How to redeem ?</div>
                                    <div className="introduction__element_desc">
                                        <div className="element__desc_single">
                                            <i className="ri-checkbox-circle-line"></i>
                                            <span className="text-body-1">
                                                Log in to your account
                                            </span>
                                        </div>
                                        <div className="element__desc_single">
                                            <i className="ri-checkbox-circle-line"></i>
                                            <span className="text-body-1">
                                                Go to the 'My Rewards' section
                                            </span>
                                        </div>
                                        <div className="element__desc_single">
                                            <i className="ri-checkbox-circle-line"></i>
                                            <span className="text-body-1">
                                                Click on 'Redeem Rewards' and follow the instructions
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="referral__introduction_element">
                                <div className="introduction__element_img">
                                    <img src={require('../../assets/media/pictures/static/referred-2.png')} alt=""/>
                                </div>
                                <div className="introduction__element_text">
                                    <div className="introduction__element_title text-heading-5">Your rewards</div>
                                    <div className="introduction__element_desc text-body-1">
                                        Discounts, gift cards, exclusive products
                                    </div>
                                </div>
                            </div>
                            <div className="referral__introduction_element">
                                <div className="introduction__element_img">
                                    <img src={require('../../assets/media/pictures/static/referred-3.png')} alt=""/>
                                </div>
                                <div className="introduction__element_text">
                                    <div className="introduction__element_title text-heading-5">Need Help?</div>
                                    <div className="introduction__element_desc text-body-1">
                                        Share the love for our brand and help your friends enjoy our products while you
                                        earn rewards
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={'my__referral_banner'} style={{
                backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url('${require('../../assets/media/pictures/static/my-referral-banner.jpeg')}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
                <div className="container_s">
                    <div className="my__referral_wrapper">
                        <div className="my__referral_text">
                            <div className="my__referral_title text-heading-4">Refer Solar max</div>
                            <div className="my__referral_desc text-body-1">You've got two ways to earn: refer friends and small
                                businesses by clicking on the left. Refer bigger businesses that could use assistance
                                ordering at scale on the right. Easy peasy.
                            </div>
                        </div>
                        <div className="my__referral_cta">
                            <Link to={'/referral/dashboard'} onClick={props.handleLink}>My referrals <i className="ri-arrow-right-line"></i></Link>
                        </div>
                    </div>
                </div>
            </section>

            <section id={'create__referral'} className="create__referral">
                <div className="container">
                    <div className="create__referral_wrapper">
                        <div className="create__referral_text">
                            <div className="create__referral_title text-heading-4">Refer SolarMax</div>
                            <div className="create__referral_desc text-body-1">
                                You've got two ways to earn: refer friends and small businesses by
                                clicking on the left. Refer bigger businesses that could use
                                assistance ordering at scale on the right. Easy peasy.
                            </div>
                        </div>
                        {referral.link ?
                            <>
                                <div className="create__referral_form">
                                    <div className="referral__form_title text-heading-4">Referral Link & Code</div>
                                    <div className="referral__form_sub"><span>Friends receive 0 %</span> | <span>You receive 30 %</span>
                                    </div>
                                    <label htmlFor="referral__link" >Referral link</label>
                                    <input type="text" id={'referral__link'} defaultValue={referral.link} onClick={event => copyToClipboard(event)} style={{ cursor: 'pointer', userSelect: "none" }} />
                                    <label htmlFor="referral__link">Referral code</label>
                                    <input type="text" id={'referral__link'} defaultValue={referral.code} onClick={event => copyToClipboard(event)} style={{ cursor: 'pointer' }} />
                                    <div style={{display: 'flex', gap: '24px', flexWrap:"wrap"}}>
                                        <button style={{background: 'var(--border-subtle)', flexGrow: 1}} onClick={changeUser}>Change</button>
                                        <button style={{flexGrow: 1}} onClick={changeUser}>Copy link</button>
                                    </div>

                                </div>
                            </>
                            :
                            <Form onSubmit={event => handleSubmit(event)} className="create__referral_form">
                                <div className="referral__form_title text-heading-4">Referral Link & Code</div>
                                <div className="referral__form_sub"><span>Friends receive 0 %</span> | <span>You receive 30 %</span></div>
                                <input className={'text-body-1'} type="email" id='referral__email' value={referralEmail} onChange={event => handleInput(event)} placeholder={'Email'} name={'referral__email'}/>
                                <button className={'text-body-1'}>Get link <i className="ri-link-m"></i></button>
                            </Form>
                        }

                    </div>
                </div>
            </section>

            <Faq/>

            <section className="homepage__cta">
                <div className="container">
                    <div className="cta__wrapper">
                        <div className="cta__title text-heading-3">
                            Start your solar journey with a free quote
                        </div>
                        <div className="cta__desc text-body-1">
                            SolarMax is dedicated to providing the highest quality energy alternatives at affordable
                            prices. Enjoy easy financing options and low monthly payments that make shifting to solar
                            energy simple and stress-free.
                        </div>
                        <div className="cta__cta text-heading-6">
                            <button className={'text-heading-6'} onClick={props.toggleForm}>Get a free quote</button>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default ReferralIntro;