import React, {useEffect, useState} from 'react';
import Pagination from "../views/includes/pagination";
import {Form, Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

function Reviews(props) {
    const [reviews, setReviews] = useState(null)
    const [pagination, setPagination] = useState({
        items: 8,
        pages: null
    })
    const [reviewForm, setReviewForm] = useState({rating: 0})
    const [openForm, setOpenForm] = useState(false)
    const [subEmail, setSubEmail] = useState(null)
    const [error, setError] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [currentItems, setCurrentItems] = useState(8)
    const [formSubmit, setFormSubmit] = useState(false)
    const [subscriptionSubmit, setSubscriptionSubmit] = useState(false)

    useEffect(()=>{
        fetch(`${props.debugValue}/api/reviews/${pagination.items}`).then(res=>res.json()).then(data=> {
            setReviews(data.all)

            setPagination((old)=> ({
                    ...old,
                    pages: data.pages
                })
            )
        }).catch(err=>console.log(err))
        setReviewForm((old)=>{
            return{
                ...old,
                user_id: props.bd.user_id
            }
        })
    }, [])

    useEffect(()=>{
        fetch(`${props.debugValue}/api/reviews/${pagination.items*currentPage}`).then(res=>res.json()).then(data=>{
            setReviews(data.all)
        }).catch(err=>console.log(err))

    }, [currentPage])
    const handleSubscription = (e) =>{
        e.preventDefault()
        if (!subEmail){
            setError('Please add the proper email')
        }else if(!subEmail.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )){
            setError('Please add the proper email')
        }else{
            setError('')
            fetch(`${props.debugValue}/api/subscribe`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + props.debugValue.t,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({u_data: subEmail ? subEmail : '' , u_br: props.bd.u_br, u_os: props.bd.u_os})
            }).then(()=>setSubscriptionSubmit(!subscriptionSubmit)).catch(err=>console.log(err))
        }
    }
    const handleEmail = (e) =>{
        setSubEmail(e.target.value)
    }
    const handleInput = (e) =>{
        let {name, value} = e.target

        setReviewForm((old)=>{
            return{
                ...old,
                [name]: value
            }
        })
    }
    const handleNextPagination = async (e) =>{
        if (currentPage < pagination.pages){
            setCurrentPage(currentPage+1)
        }
    }

    const handleBeforePagination = async (e) =>{
        if ((currentPage > 1 && currentPage < pagination.pages) || currentPage === pagination.pages){
            setCurrentPage(currentPage-1)
        }
    }

    const handleForm = (e) =>{
        e.preventDefault()
        setOpenForm(!openForm)
    }

    const handleFormSubmit = (e) =>{
        e.preventDefault()

        fetch(`${props.debugValue}/api/leave-review`,{
            method: 'POST',
            body: JSON.stringify(reviewForm),
            headers: {
                'Authorization': 'Bearer ' + props.debugValue.t,
                'Content-Type': 'application/json'
            },
        }).then(()=>setFormSubmit(!formSubmit)).catch(err=>console.log(err))
    }

    let rating = [1,2,3,4,5]

    const handleRating = (e) =>{
        setReviewForm((old)=>{
            return{
                ...old,
                rating: e.target.dataset.rating
            }
        })
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Reviews</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/reviews`}/>
                </Helmet>
            </HelmetProvider>
            <section className="global__hero_single" style={{backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url('${require('../assets/media/pictures/static/reviews_hero.jpeg')}')`, backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition:'center'}}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="single__hero_wrapper">
                        <div className="single__hero_text">
                            <div className="single__hero_title text-heading-3">See what our valued customers say about SolarMax</div>
                            <div className="single__hero_desc  text-heading-5">Our reputation speaks for itself. Here are a few examples of why we are one of the top-recommended solar installers in Southern California</div>
                            <div className="single__hero_cta">
                                <button className={'text-heading-7'} onClick={event => handleForm(event)}>Leave a review</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{position: "fixed", top: '0', maxWidth: '1440px'}} className={`get__quote ${openForm? 'get__quote_active' : ''}`}>
                        <div className="get__quote_form get__quote_form_wrapper">
                            {!formSubmit ?
                                <Form onSubmit={event => handleFormSubmit(event)}>
                                    <input required onChange={event => handleInput(event)} type="text" name={'name'} placeholder={'First name'}/>
                                    <input required onChange={event => handleInput(event)} type="text" name={'surname'} placeholder={'Last name'}/>
                                    <input required onChange={event => handleInput(event)} type="text" name={'project__number'} placeholder={'Project number'}/>
                                    <input required onChange={event => handleInput(event)} type="email" name={'email'} placeholder={'Email'}/>
                                    <div>{rating.map((item)=>{
                                        return(
                                            <i onClick={event => handleRating(event)} data-rating={rating.indexOf(item)+1} style={{fontSize: '30px', cursor: "pointer"}} className={`ri-star-s-${reviewForm.rating > rating.indexOf(item) ? 'fill' : 'line'}`}></i>
                                        )
                                    })} <span className={'text-body-3'}>rating</span></div>

                                    <textarea onChange={event => handleInput(event)} name={"comment"} id="" cols="30" rows="5" placeholder={'Your comment'}></textarea>
                                    {error && <div>{error}</div>}
                                    <div style={{display: "flex", gap: '16px'}}><button onClick={event => handleForm(event)} style={{background: 'var(--branding-secondary)', border: '1px solid var(--branding-secondary)'}} className={'text-heading-7'}><i className="ri-arrow-left-s-line"></i></button><button style={{flexGrow: 1}} className={'text-heading-7'}>Leave a review</button></div>
                                </Form>

                                :
                                subscriptionSubmit ?
                                    <>
                                        <div className={'text-heading-3'} style={{textAlign: "center", margin: '12px 16px'}}>Thank you!</div>
                                        <button onClick={event => handleForm(event)} style={{background: 'var(--branding-secondary)', border: '1px solid var(--branding-secondary)', width: '100%', borderRadius: '4px', padding: '8px 24px'}} className={'text-heading-7'}>Close</button>
                                    </>

                                    :
                                    <>
                                        <div className={'text-heading-6'} style={{textAlign: "center"}}>The review will be published shortly !</div>
                                        <div className={'text-heading-6'} style={{textAlign: "center", margin: '12px 16px'}}>But wait there's more…</div>
                                        <p className={'text-body-1'}>Stay informed about industry news, government incentives, and our special offers by subscribing to our monthly solar newsletter.</p>
                                        <Form onSubmit={event => handleSubscription(event)} style={{margin: '24px 0 16px 0'}}>
                                            <input onChange={event => handleEmail(event)} type="email" style={{width: '100%', padding: "9px 16px", borderRadius: "4px", outline: "none", border: '1px solid gray'}} value={subEmail? subEmail : ''}/>
                                            <span style={{color: "red"}} className={'text-body-3'}>{error && error}</span>
                                            <div style={{marginTop: '16px', display: "flex", gap: '16px'}}><button type={"submit"} style={{border: "none",flexGrow: 1, background: 'var(--button-primary)', padding: '8px 24px', borderRadius: '4px', cursor: "pointer"}}>Yes, sign up me up!</button><div onClick={props.toggleForm} style={{border: "none",flexGrow: 1, textAlign:"center", background: 'var(--button-custom)', padding: '7px 24px', borderRadius: '4px', cursor: "pointer"}}>No thanks</div></div>
                                        </Form>
                                        <p className={'text-body-3'}>We won't share your email with anyone, and you can unsubscribe at any time. Learn more in our <Link style={{color: 'var(--branding-secondary)'}} to={'/privacy'}>privacy policy</Link>.</p>
                                    </>
                            }

                    </div>
                    <div className="get__quote_wrapper" onClick={handleForm}></div>
                </div>
            </section>

            <section className="reviews">
                <div className="container_s">
                    <div className="reviews__wrapper">
                        <div className="reviews__text">
                            <div className="reviews__title text-heading-3">Reviews</div>
                            <div className="reviews__desc text-heading-6">Verified reviews of SolarMax clients</div>
                        </div>
                        <div className="reviews__elements">
                            {reviews && reviews.map(review=>{
                                return(
                                    <div key={review.id} className="review">
                                        <div className="review__name text-heading-6">{review.name +" "+ (review.surname ? review.surname : '')} <span className="review__date text-body-3">{new Date(review.created_at).toLocaleDateString("en-US", {day: 'numeric', month: 'long', year: 'numeric'})}</span></div>

                                        <div className="review__rating">{[...Array(parseInt(review.rating))].map((num)=>{
                                            return(
                                                <i className="ri-star-fill full__star"></i>
                                            )
                                        })}{[...Array(5-parseInt(review.rating))].map((enu)=>{
                                            return(
                                                <i className="ri-star-fill empty__star"></i>
                                            )
                                        })}</div>
                                        <div className="review__comment text-body-1">{review.comment}</div>

                                    </div>
                                )
                            })}
                        </div>
                        <div>{pagination.pages && <Pagination handleLink={props.handleLink} currentPage={currentPage} pages={pagination.pages} handleBeforePagination={handleBeforePagination} handleNextPagination={handleNextPagination}/>}</div>

                    </div>
                </div>
            </section>

            <section className="homepage__cta">
                <div className="container_s">
                    <div className="cta__wrapper">
                        <div className="cta__title text-heading-3">
                            Start your solar journey with a free quote
                        </div>
                        <div className="cta__desc text-body-1">
                            SolarMax is dedicated to providing the highest quality energy alternatives at affordable prices. Enjoy easy financing options and low monthly payments that make shifting to solar energy simple and stress-free.
                        </div>
                        <div className="cta__cta text-heading-6">
                            <button className={'text-heading-6'} onClick={props.toggleForm}>Get a free quote</button>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Reviews;