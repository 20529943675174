import '../../assets/css/shared.css'
import 'remixicon/fonts/remixicon.css'
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import HeaderLayout from "./header-layout";
import HomeMain from "../../components/home/home-main";
import BusinessOwnerBattery from "../../components/solutions/business-owner-battery";
import HomeOwnerBattery from "../../components/solutions/home-owner-battery";
import BusinessOwnerSolar from "../../components/solutions/business-owner-solar";
import HomeOwnerSolar from "../../components/solutions/home-owner-solar";
import Article from "../../components/article";
import ArticlesHome from "../../components/articles-home";
import Financing from "../../components/financing";
import Contact from "../../components/contact";
import SpecialOffers from "../../components/special-offers";
import Warranties from "../../components/warranties";
import FormComplete from "../../components/form-complete";
import {useEffect, useState} from "react";
import Error from "../../components/error";
import AboutUs from "../../components/about-us";
import Locations from "../../components/locations";
import Reviews from "../../components/reviews";
import Roofing from "../../components/solutions/roofing";
import ReferralIntro from "../../components/referral/referral-intro";
import ReferralDashboard from "../../components/referral/referral-dashboard";
import ReferralConfirmation from "../../components/referral/referral-confirmation";
import FormEstimate from "../../components/form/form-estimate";
import SolarmaxVsTesla from "../solarmax_seo/solarmax-vs-tesla";
import Resources from "../solarmax_seo/resources";
import ElectricCarCharging from "../solarmax_seo/electric-car-charging";
import LedLighting from "../solarmax_seo/led-lighting";
import SolarTaxCredit from "../solarmax_seo/solar-tax-credit";
import VistaCieloHomes from "../solarmax_seo/vista-cielo-homes";
import SmxSolarPanels from "../solarmax_seo/smx-solar-panels";
import WhyChooseSolarmax from "../solarmax_seo/why-choose-solarmax";


const Main = () => {
    const [showForm, setShowForm] = useState(false)
    const [pass, setPass] = useState(null)

    const toggleForm = () => {
        setShowForm(!showForm)
    }


    const exploreValues = {
        financing: {
            title: 'Financing',
            description: 'Our flexible, zero-down financing options can make owning a solar system feasible for just about anyone',
            link: '/financing',
            photo: '../assets/media/pictures/static/1. Financing.jpg'
        },
        warranties: {
            title: 'Warranties',
            description: 'Enjoy peace of mind with warranties and production guarantees that protect your investment for decades to come',
            link: '/warranties',
            photo: '../assets/media/pictures/static/2. Warranties.jpg'
        },
        specials: {
            title: 'Special offers',
            description: 'Discover the latest deals and discounts, the best incentives and giveaways, and the lowest financing rates in our special offers section',
            link: '/special-offers',
            photo: '../assets/media/pictures/static/3. Special offers.jpg'
        },
        blogs: {
            title: 'Blog',
            description: 'Get up to speed on what\'s happening in the solar industry by checking out our compelling',
            link: '/blog',
            photo: '../assets/media/pictures/static/4. Blog offer card.png'
        },
        company: {
            title: 'About-us',
            description: 'See what sets us apart from other solar companies and why so many clients recommend us',
            link: '/about-us',
            photo: '../assets/media/pictures/static/2. Warranties.jpg'
        }
    }

    const [browserDetails, setBrowserDetails] = useState({
        u_os: null,
        u_br: null,
        u_id: null,
        u_pv: []
    })

    const cleanup = async (event) => {
        let tmp_info = browserDetails
        if (process.env.REACT_APP_DEBUG === 'true') {
            try {
                fetch(`${pass}/api/user-info/leave`, {
                    method: 'POST',
                    body: JSON.stringify({tmp_info}),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => res.json()).then(data => {
                    if (data.message === 200) {
                        // console.log(data.message)
                    } else {
                        // console.log(data.message)
                    }
                })
            } catch (error) {
                console.error('Error sending data on unload:', error);
            }
        }
    };

    const addPages = (value) => {
        let tmp_list = browserDetails.u_pv
        tmp_list.push(value)
        cleanup();
        setBrowserDetails({...browserDetails, u_pv: tmp_list});
    }

    useEffect(() => {
        if (window.innerWidth < 767) {
            setBrowserDetails((old) => {
                return {
                    ...old,
                    u_os: "mobile"
                }
            })
        } else if (window.innerWidth > 767 && window.innerWidth < 1280) {
            setBrowserDetails((old) => {
                return {
                    ...old,
                    u_os: "tablet"
                }
            })
        } else {
            setBrowserDetails((old) => {
                return {
                    ...old,
                    u_os: "desktop"
                }
            })
        }

        navigator.sayswho = (function () {
            var N = navigator.appName, ua = navigator.userAgent, tem,
                M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*([\d\.]+)/i);
            if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
            M = M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
            return M.join(' ');
        })(setBrowserDetails((old) => {
            return {
                ...old,
                u_br: navigator.sayswho
            }
        }));
        setBrowserDetails((old) => {
            return {
                ...old,
                u_id: Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36)
            }
        })
        if (process.env.REACT_APP_DEBUG === "true") {
            setPass(`https://${process.env.REACT_APP_URL_DEV}`)
        } else {
            setPass(`http://${process.env.REACT_APP_URL}`)
        }

    }, [])

    const handleLink = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    const router = createBrowserRouter([
        {path: '/form-complete', element: <FormComplete toggleForm={toggleForm} browserDetails={browserDetails}/>},
        {
            element: <HeaderLayout toggleForm={toggleForm} addPages={addPages} showForm={showForm} debugValue={pass}
                                   browserDetails={browserDetails}/>, children: [
                {
                    path: '*',
                    element: <Error browserDetails={browserDetails} toggleForm={toggleForm} handleLink={handleLink}/>
                }, {
                    path: '/solarmax-vs-tesla',
                    element: <SolarmaxVsTesla toggleForm={toggleForm} browserDetails={browserDetails}/>
                },

                {path: '/resources', element: <Resources toggleForm={toggleForm} browserDetails={browserDetails}/>},
                {path: '/resources', element: <Resources toggleForm={toggleForm} browserDetails={browserDetails}/>},
                {path: '/solar-products/electric-car-charging/', element: <ElectricCarCharging toggleForm={toggleForm} browserDetails={browserDetails}/>},
                {path: '/commercial/led-lighting', element: <LedLighting toggleForm={toggleForm} browserDetails={browserDetails}/>},
                {path: '/solar-tax-credit', element: <SolarTaxCredit toggleForm={toggleForm} browserDetails={browserDetails}/>},
                {path: '/vista-cielo-homes', element: <VistaCieloHomes toggleForm={toggleForm} browserDetails={browserDetails}/>},
                {path: '/solar-products/smx-solar-panels/', element: <SmxSolarPanels toggleForm={toggleForm} browserDetails={browserDetails}/>},
                {path: '/why-choose-solarmax', element: <WhyChooseSolarmax toggleForm={toggleForm} browserDetails={browserDetails}/>},
                {path: '/', element: <HomeMain toggleForm={toggleForm} showForm={showForm} debugValue={pass}/>},
                {
                    path: '/businesses/battery',
                    element: <BusinessOwnerBattery toggleForm={toggleForm} handleLink={handleLink}
                                                   browserDetails={browserDetails} debugValue={pass}/>
                },
                {
                    path: '/businesses/solar',
                    element: <BusinessOwnerSolar toggleForm={toggleForm} handleLink={handleLink}
                                                 browserDetails={browserDetails} debugValue={pass}/>
                },
                {
                    path: '/homeowners/battery',
                    element: <HomeOwnerBattery toggleForm={toggleForm} browserDetails={browserDetails}
                                               handleLink={handleLink} debugValue={pass}/>
                },
                {
                    path: '/homeowners/solar',
                    element: <HomeOwnerSolar toggleForm={toggleForm} browserDetails={browserDetails}
                                             handleLink={handleLink} debugValue={pass}/>
                },
                {
                    path: '/roofing',
                    element: <Roofing toggleForm={toggleForm} browserDetails={browserDetails} debugValue={pass}/>
                },
                {
                    path: '/blog',
                    element: <ArticlesHome toggleForm={toggleForm} showForm={showForm} browserDetails={browserDetails}
                                           explore={[exploreValues.company, exploreValues.specials]} debugValue={pass}/>
                },
                {
                    path: '/blog/:path',
                    element: <Article toggleForm={toggleForm} browserDetails={browserDetails} showForm={showForm}
                                      debugValue={pass}/>
                },
                {
                    path: '/locations/:path',
                    element: <Article toggleForm={toggleForm} browserDetails={browserDetails} showForm={showForm}
                                      debugValue={pass}/>
                },
                {
                    path: '/financing',
                    element: <Financing toggleForm={toggleForm} browserDetails={browserDetails}
                                        explore={[exploreValues.warranties, exploreValues.specials]} debugValue={pass}/>
                },
                {
                    path: '/warranties',
                    element: <Warranties toggleForm={toggleForm} browserDetails={browserDetails}
                                         explore={[exploreValues.specials, exploreValues.financing]} debugValue={pass}/>
                },
                {
                    path: '/contact-us',
                    element: <Contact toggleForm={toggleForm} browserDetails={browserDetails} debugValue={pass}/>
                },
                {
                    path: '/reviews',
                    element: <Reviews toggleForm={toggleForm} browserDetails={browserDetails} debugValue={pass}
                                      handleLink={handleLink}/>
                },
                {
                    path: '/service-areas',
                    element: <Locations browserDetails={browserDetails} toggleForm={toggleForm} debugValue={pass}/>
                },
                {
                    path: '/special-offers',
                    element: <SpecialOffers toggleForm={toggleForm} browserDetails={browserDetails}
                                            explore={[exploreValues.financing, exploreValues.warranties]}
                                            debugValue={pass}/>
                },
                {
                    path: '/about-us',
                    element: <AboutUs toggleForm={toggleForm} browserDetails={browserDetails}
                                      explore={[exploreValues.blogs, exploreValues.specials]} debugValue={pass}/>
                },
                {
                    path: '/referral',
                    element: <ReferralIntro toggleForm={toggleForm} browserDetails={browserDetails}
                                            handleLink={handleLink}
                                            explore={[exploreValues.blogs, exploreValues.specials]} debugValue={pass}/>
                }, {
                    path: '/reviews',
                    element: <Reviews toggleForm={toggleForm} browserDetails={browserDetails} debugValue={pass}
                                      handleLink={handleLink}/>
                },
                {
                    path: '/solar-estimate',
                    element: <FormEstimate toggleForm={toggleForm} browserDetails={browserDetails}
                                           handleLink={handleLink}
                                           explore={[exploreValues.blogs, exploreValues.specials]} debugValue={pass}/>
                }, {
                    path: '/reviews',
                    element: <Reviews toggleForm={toggleForm} browserDetails={browserDetails} debugValue={pass}
                                      handleLink={handleLink}/>
                },
                {
                    path: '/referral/dashboard',
                    element: <ReferralDashboard toggleForm={toggleForm} browserDetails={browserDetails}
                                                explore={[exploreValues.blogs, exploreValues.specials]}
                                                debugValue={pass}/>
                }, {
                    path: '/reviews',
                    element: <Reviews toggleForm={toggleForm} browserDetails={browserDetails} debugValue={pass}
                                      handleLink={handleLink}/>
                },
                {
                    path: '/my-referral/:code',
                    element: <ReferralConfirmation toggleForm={toggleForm} browserDetails={browserDetails}
                                                   explore={[exploreValues.blogs, exploreValues.specials]}
                                                   debugValue={pass}/>
                }, {
                    path: '/reviews',
                    element: <Reviews toggleForm={toggleForm} browserDetails={browserDetails} debugValue={pass}
                                      handleLink={handleLink}/>
                },
            ]
        }
    ])

    return browserDetails.u_os && pass && (<RouterProvider router={router}/>);
}

export default Main;
