import '../../assets/css/form-estimate.css'

import {
    AdvancedMarker,
    APIProvider,
    Map,
    useAdvancedMarkerRef,
    useMap,
    useMapsLibrary
} from '@vis.gl/react-google-maps';

import React, {useEffect, useRef, useState} from "react";
import {Form} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import FormEstimateComplete from "./form-estimate-complete";

const FormEstimate = (props) => {

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [calculationResult, setCalculationResult] = useState(null)
    const [formDetails, setFormDetails] = useState({
        initialWidth: 0,
        offset: 0,
        step: 1
    });

    const [status, setStatus] = useState(301);

    const [modal, setModal] = useState({
        modal: false,
        type: ''
    })

    const [formData, setFormData] = useState({
        monthlyBill: 200,
        electricityRate: 0.30,
        date: '',
        phone: '',
        firstName: '',
        lastName: '',
        email: '',
        ev: 'no',
        ev_count: '0',
        address: '',
        reasons: [],
        type: '',
        // interests: [],
        daytime: []
    })

    const [stepper, setStepper] = useState({
        monthlyBill: false,
        electricityRate: false,
        date: false,
        phone: false,
        firstName: false,
        lastName: false,
        email: false,
        ev: false,
        ev_count: false,
        address: false,
        reasons: false,
        type: false
    })

    useEffect(()=>{
        let tmp_stepper = stepper
        let keys = Object.keys(tmp_stepper);

        keys.forEach(key => {
            tmp_stepper[key] = !!(formData[key] && formData[key].length > 0);
        })

        setStepper(tmp_stepper)
    },[formData, stepper])

    const handleFormInputs = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const [markerRef, marker] = useAdvancedMarkerRef();

    const API_KEY = process.env.REACT_APP_GKEY ?? "YOUR_API_KEY";

    const PlaceAutocomplete = ({onPlaceSelect}) => {
        const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
        const inputRef = useRef(null);
        const places = useMapsLibrary("places");

        useEffect(() => {
            if (!places || !inputRef.current) return;
            const options = {
                fields: ["geometry", "name", "formatted_address"],
            };
            setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
        }, [places]);

        useEffect(() => {
            if (!placeAutocomplete) return;
            placeAutocomplete.addListener("place_changed", () => {
                onPlaceSelect(placeAutocomplete.getPlace());
                setFormData({...formData, address: placeAutocomplete.getPlace().formatted_address});
                setStepper({...stepper, address: true});
            });
        }, [onPlaceSelect, placeAutocomplete]);
        if (document.getElementById("address_input") && selectedPlace){
            document.getElementById("address_input").value = selectedPlace.formatted_address;
        }
        return (
            <div className="autocomplete-container_s">
                <input type="text" required className={'mb-2'} name='address' id="address_input" placeholder="Address" ref={inputRef}/>
            </div>
        );
    };
    console.log(calculationResult);
    const DragEnd = (e)=>{
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${marker.position.lat},${marker.position.lng}8&key=${API_KEY}`)
            .then(res => res.json())
            .then(data => {
                document.getElementById("address_input").value = data.results[0].formatted_address;
                setSelectedPlace({...selectedPlace, formatted_address: data.results[0].formatted_address});
                setFormData({...formData, address: data.results[0].formatted_address});
            })
    }

    const MapHandler = ({place, marker}) => {
        const map = useMap();
        // if (map && place && marker) {
        //     setFormData({...formData, address: place.formatted_address});
        // }

        useEffect(() => {
            if (!map || !place || !marker) return;
            if (place.geometry?.viewport) {
                map.fitBounds(place.geometry.viewport);
            }
            marker.position = place.geometry?.location;

        }, [map, place, marker]);
        return null;
    };

    useEffect(() => {
        if (status === 301) {
            setFormDetails({
                ...formDetails,
                initialWidth: parseInt(document.querySelector('.form-wrapper').getBoundingClientRect().width)
            });
        }
    }, []);

    const moveFw = (e) => {
        setFormDetails({
            ...formDetails,
            offset: formDetails.offset - formDetails.initialWidth,
            step: formDetails.step + 1
        })

        if (formDetails.step === 6) {
            document.getElementById('progress-container_s').style.visibility = 'hidden'
        }
    }

    const moveBc = (e) => {
        e.preventDefault();
        setFormDetails({
            ...formDetails,
            offset: formDetails.offset + formDetails.initialWidth,
            step: formDetails.step - 1
        })

    }

    const checkButtonHandle = (e) => {
        let button = e.currentTarget
        button.parentElement.classList.toggle('check-label-checked')
        if (button.dataset.category === 'reason') {
            button.closest('.main-interaction').querySelector('.interaction-next').removeAttribute('disabled');
            let value = e.target.value
            let tmp_reasons = formData.reasons
            const exists = tmp_reasons.some(item=>item.value === value)
            if (exists){
                tmp_reasons =  tmp_reasons.filter(c => {
                    return c.value !== value
                })
            }else{
                tmp_reasons.push({name: e.target.name, value: value})
            }
            setFormData({...formData, reasons: tmp_reasons})
            setStepper({...stepper, reasons: true})

            let context = ``

            for (let i = 0; i < tmp_reasons.length; i++) {

                if (tmp_reasons.length === 1) {
                    context += tmp_reasons[i].name.charAt(0).toUpperCase() + tmp_reasons[i].name.slice(1)
                } else if (i === 0) {
                    context += tmp_reasons[i].name.charAt(0).toUpperCase() + tmp_reasons[i].name.slice(1) + ", "
                } else if (i !== tmp_reasons.length - 1 && tmp_reasons.length !== 1) {
                    context += tmp_reasons[i].name + ", "
                } else {
                    context += " and " + tmp_reasons[i].name
                }
            }

            document.querySelector('.check-media').innerHTML = `<div class="check-content"><i class="fa-solid fa-piggy-bank"></i><div class="check-text">The right solar system can: <span class="fw-bold">${context}</span></div></div>`
            if (tmp_reasons.length === 0) {
                document.querySelector('.check-media').innerHTML = ``
                setStepper({...stepper, reasons: false})

            }
        }
        // else if (button.dataset.category === 'interests') {
        //     let value = e.target.value
        //     let tmp_reasons = formData.interests
        //     const exists = tmp_reasons.some(item=>item.value === value)
        //     if (exists){
        //         tmp_reasons =  tmp_reasons.filter(c => {
        //             return c.value !== value
        //         })
        //     }else{
        //         tmp_reasons.push({name: e.target.name, value: value})
        //     }
        //     setFormData({...formData, interests: tmp_reasons})
        //
        // }
        else if (button.dataset.category === 'daytime') {
            let value = e.target.value
            let tmp_daytime = formData.daytime
            const exists = tmp_daytime.some(item=>item.value === value)
            if (exists){
                tmp_daytime =  tmp_daytime.filter(c => {
                    return c.value !== value
                })
            }else{
                tmp_daytime.push({name: e.target.name, value: value})
            }
            setFormData({...formData, daytime: tmp_daytime})

        }
    }

    const radioButtonHandle = (e) => {
        let radio_btn = e.currentTarget
        // radio_btn.closest('.main-interaction').querySelector('.interaction-next').removeAttribute('disabled');
        radio_btn.closest('.interaction-select').querySelectorAll('label').forEach(item => {
            item.classList.remove('radio-label-checked')
        });
        radio_btn.closest('label').classList.toggle('radio-label-checked')
        setFormData({...formData, [e.target.name]: e.target.value})
        setStepper({...stepper, [e.target.name]: true})

        if (radio_btn.dataset.category === 'type') {
            document.querySelector('.type-media').innerHTML = hints[e.target.dataset.category][e.target.value]
        } else if (radio_btn.dataset.category === 'ev_intro') {
            document.querySelector('.secondary-step').innerHTML = hints[e.target.dataset.category][e.target.value]

            let evs = document.getElementsByClassName('ev-radio-button')
            if (evs){
                Array.from(evs).forEach(ev => {
                    ev.onclick = radioButtonHandle
                })
            }
        }
    }

    let hints = {
        type: {
            residential: `<div class="check-content"><i class="ri-home-5-line"></i><div class="map-text">Getting a Solar system is a great way to increase the value of your house! The average project adds between 3% and 7% to a house’s equity. Plus, it shows you are doing your part to protect the planet for the next generation.</div></div>`,
            commercial: `<div class="check-content"><i class="ri-building-line"></i><div class="map-text">Using solar power for your business can help your business save money, guarantee your operations stay online when the power goes down, and help cut your cost of operations. Plus, it protects your bottom line against carbon taxes in the future and helps build an eco-friendly brand.</div></div>`,
            industrial: `<div class="check-content"><i class="ri-dashboard-horizontal-line"></i><div class="map-text">Solar industrial and utility projects can get major incentives and rebates from the government and can earn big profits over time. Not to mention, they show your investing in the future of our planet.</div></div>`,
        },
        property_rel: {
            Yes: "Installing a solar system can be a sound financial investment for property owners. The upfront cost of the system can be recouped over time through energy savings. Along with solar systems providing owners with a degree of energy independence and resilience.",
            No: "Solar systems provide a level of price stability for tenants. While utility rates may fluctuate over time, especially due to factors like fuel costs, solar energy costs remain relatively stable. Also, solar systems can help tenants reduce their electricity bills. "
        },
        trees: {
            Yes: "Solar panels need direct sunlight to generate electricity efficiently. If there are trees that cast significant shade on the area where solar panels would be installed, it can greatly reduce the solar system's performance. In such cases, selectively removing or trimming trees that obstruct sunlight may be necessary to maximize solar energy production.",
            No: "Trees support a diverse range of plant and animal species. Removing trees can disrupt the natural habitat for birds, insects, and other wildlife that rely on trees for shelter, food, and nesting. This loss of biodiversity can have cascading effects on local ecosystems. Cutting down trees reduces this natural air filtration capacity, potentially leading to decreased air quality and increased carbon emissions."
        },
        roof: {
            Yes: "No worries! We will check the condition of the roof in person before we finalize the quote.",
            No: "That’s great! You probably won’t need to repair the roof before you install the panels.",
            NotSure: "No worries! We will check the condition of the roof in person before we finalize the quote.",
        },
        battery: {
            Yes: "In the event of a power outage, a solar system with battery storage can provide backup power. Traditional grid-tied solar systems without batteries are designed to shut down during outages for safety reasons.",
            No: "Without battery storage, solar system owners remain connected to the grid and can rely on net metering programs. Net metering allows solar system owners to feed excess energy they generate back into the grid, effectively \"banking\" it as credits."
        },
        ev_intro: {
            yes: `<div class="main-interaction_s">
                        <div class="interaction-intro">
                                    <div class="interaction-subtitle">
                                        <i class="ri-sun-line"></i>How many EVs do you have?
                                    </div>
                                </div>
                                <div class="interaction-select">
                                    <div class="select-container_s">
                                        <div class="radio-item radio-item-small">
                                                                                           
                                            <label for="q6-1" class="radio-label-checked">1<input class="radio-button ev-radio-button" checked="checked" data-category="evCount" name="ev_count" type="radio" value="1" id="q6-1"></label>
                                        </div>
                                        <div class="radio-item radio-item-small">
                                            <label for="q6-2">2<input class="radio-button ev-radio-button" data-category="evCount" name="ev_count" type="radio" value="2" id="q6-2"></label>
                                        </div>
                                        <div class="radio-item radio-item-small">
                                            <label for="q6-3">3+<input class="radio-button ev-radio-button" data-category="evCount" name="ev_count" type="radio" value="3" id="q6-3"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="check-content">
                                            <i class="ri-car-line"></i><div class="map-text">Perfect! You're already reducing carbon on the road too. Keep it up!</div>
                                        </div>
                                        </div>`,
            no: ""
        },
    }

    const handleSubmit = (e) =>{
        setStatus(300)
        e.preventDefault()
        let tmp_data = formData
        tmp_data.u_id = props.browserDetails.u_id
        fetch(`${props.debugValue}/api/form-estimated`,{
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json()).then(data => {
            if (data.status === 200) {
                setStatus(200)
                console.log(data)
                setCalculationResult({...calculationResult,
                    cash_save: data.cash_save,
                    loan_save: data.loan_save,
                    lease_save: data.lease_save,
                })
            }else if (data.status === 400) {
                setStatus(400)
                alert('The estimate was not sent, please try again.')
            }else if (data.status === 401) {
                alert('Issue with estimation please try again.')
            }
        })
    }

    const handleModal = (e) =>{
        setModal({...modal, modal: !modal.modal, type: e.target.dataset.modal})
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - System estimator</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/solar-estimate`}/>
                </Helmet>
            </HelmetProvider>
            <APIProvider
                apiKey={API_KEY}
                solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
            >
                {
                    status === 301 &&
                    <section id="main">
                        <div className="container_s g-0">
                            <section className="progress-container" id={'progress-container'}>
                                <div className="progress-item">
                                    <div data-step="1"
                                         className={`progress-bar ${(formDetails.step < 2 || formDetails.step === 1) ? '' : 'progress-bar-active'}`}></div>
                                </div>
                                <div className="progress-item">
                                    <div data-step="2"
                                         className={`progress-bar ${(formDetails.step < 3 || formDetails.step === 2) ? '' : 'progress-bar-active'}`}></div>
                                </div>
                                <div className="progress-item">
                                    <div data-step="3"
                                         className={`progress-bar ${(formDetails.step < 4 || formDetails.step === 3) ? '' : 'progress-bar-active'}`}></div>
                                </div>
                                <div className="progress-item">
                                    <div data-step="4"
                                         className={`progress-bar ${(formDetails.step < 5 || formDetails.step === 4) ? '' : 'progress-bar-active'}`}></div>
                                </div>
                                <div className="progress-item">
                                    <div data-step="5"
                                         className={`progress-bar ${(formDetails.step < 6 || formDetails.step === 5) ? '' : 'progress-bar-active'}`}></div>
                                </div>
                            </section>
                            <Form onSubmit={event => handleSubmit(event)} method="post" id="act-form"
                                  className="form-wrapper">
                                <div className="form-steps" id={'form-steps'}
                                     style={{transform: `translateX(${formDetails.offset}px)`}}>
                                    <div className="form-step">
                                        <section className="main-form-content">
                                            <div className="main-interaction">
                                                <div className="interaction-intro">
                                                    <div className="interaction-title">
                                                        What's your primary reason for going solar?
                                                    </div>
                                                    <div className="interaction-desc">
                                                        <div className="separate-icon"><i className="ri-sun-line"></i>
                                                        </div>
                                                        Different solar systems solve different problems. It helps if we
                                                        know
                                                        why you want to go solar.
                                                    </div>

                                                </div>
                                                <div className="interaction-select">
                                                    <div className="select-container">
                                                        <div className="select-item">
                                                            <label htmlFor="q1-1">Savings<input data-category="reason"
                                                                                                name="save you money"
                                                                                                className="check-button"
                                                                                                onClick={event => checkButtonHandle(event)}
                                                                                                type="checkbox"
                                                                                                value="savings"
                                                                                                id="q1-1"/></label>
                                                        </div>
                                                        <div className="select-item">
                                                            <label htmlFor="q1-2">Independence<input
                                                                data-category="reason"
                                                                name="give you control over your power"
                                                                className="check-button"
                                                                onClick={event => checkButtonHandle(event)}
                                                                type="checkbox"
                                                                value="independence"
                                                                id="q1-2"/></label>
                                                        </div>
                                                        <div className="select-item">
                                                            <label htmlFor="q1-3">Power Stability<input
                                                                data-category="reason"
                                                                name="keep your lights on when the grid goes down"
                                                                className="check-button"
                                                                onClick={event => checkButtonHandle(event)}
                                                                type="checkbox"
                                                                value="stability"
                                                                id="q1-3"/></label>
                                                        </div>
                                                        <div className="select-item">
                                                            <label htmlFor="q1-4">Profit<input data-category="reason"
                                                                                               name="make you money"
                                                                                               className="check-button"
                                                                                               onClick={event => checkButtonHandle(event)}
                                                                                               type="checkbox"
                                                                                               value="profit"
                                                                                               id="q1-4"/></label>
                                                        </div>
                                                        <div className="select-item">
                                                            <label htmlFor="q1-5">Environment<input
                                                                data-category="reason"
                                                                name="help fight climate change"
                                                                className="check-button"
                                                                type="checkbox"
                                                                onClick={event => checkButtonHandle(event)}
                                                                value="environment"
                                                                id="q1-5"/></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="check-media">

                                                </div>
                                                <div className="interaction-movement">
                                                    {stepper &&
                                                        <div
                                                            className={`interaction-next  interaction-first ${stepper.reasons === false ? 'interaction-out' : ''}`}
                                                            onClick={event => moveFw(event)}>Next<i
                                                            className="ri-arrow-right-s-line"></i></div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="main-creds">
                                                <div className="main-cred">
                                                    <div className="img-container">
                                                        <img src="/partners/partner-1.png"
                                                             alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/partners/partner-2.png"
                                                             alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/partners/partner-3.png"
                                                             alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/partners/partner-4.png"
                                                             alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/partners/partner-5.png"
                                                             alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/partners/partner-7.png"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="form-step">
                                        <section className="main-form-content">
                                            <div className="main-interaction">
                                                <div className="interaction-intro">
                                                    <div className="interaction-title">
                                                        What type of solar project is this?
                                                    </div>
                                                    <div className="interaction-desc">
                                                        <div className="separate-icon"><i className="ri-sun-line"></i>
                                                        </div>
                                                        As an owner, you have full ownership of the solar system
                                                        installed
                                                        on
                                                        your property and as a tenant, you do not own the solar system.
                                                    </div>

                                                </div>
                                                <div className="interaction-select">
                                                    <div className="select-container">
                                                        <div className="radio-item">
                                                            <label htmlFor="q2-1">Residential<input data-category="type"
                                                                                                    className="radio-button"
                                                                                                    name="type"
                                                                                                    type="radio"
                                                                                                    onClick={event => radioButtonHandle(event)}
                                                                                                    value="residential"
                                                                                                    id="q2-1"/></label>
                                                        </div>
                                                        <div className="radio-item">
                                                            <label htmlFor="q2-2">Commercial<input data-category="type"
                                                                                                   className="radio-button"
                                                                                                   name="type"
                                                                                                   type="radio"
                                                                                                   onClick={event => radioButtonHandle(event)}
                                                                                                   value="commercial"
                                                                                                   id="q2-2"/></label>
                                                        </div>
                                                        <div className="radio-item">
                                                            <label htmlFor="q2-3">Industrial<input data-category="type"
                                                                                                   className="radio-button"
                                                                                                   name="type"
                                                                                                   type="radio"
                                                                                                   onClick={event => radioButtonHandle(event)}
                                                                                                   value="industrial"
                                                                                                   id="q2-3"/></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="type-media">

                                                </div>
                                                <div className="interaction-movement">
                                                    <div className={`interaction-back `}
                                                         onClick={event => moveBc(event)}><i
                                                        className="ri-arrow-left-s-line"></i></div>
                                                    <div
                                                        className={`interaction-next ${stepper.type === false ? 'interaction-out' : ''}`}
                                                        onClick={event => moveFw(event)}
                                                    >Next<i className="ri-arrow-right-s-line"></i></div>
                                                </div>
                                            </div>
                                            <div className="main-creds">
                                                <div className="main-cred">
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-enphase.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-lg.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-tesla.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-usc.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-ucr.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img
                                                            src="/assets/images/solarmax/solaredge-preferred-partner.png"
                                                            alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="form-step">
                                        <section className="main-form-content">
                                            <div className="main-interaction">
                                                <div className="interaction-intro">
                                                    <div className="interaction-title">
                                                        Let's find your roof. Please enter your address / zipcode.
                                                    </div>
                                                    <div className="interaction-desc">
                                                        <div className="separate-icon"><i className="ri-sun-line"></i>
                                                        </div>
                                                        We'll use Google Maps to check out the size and position of your
                                                        roof.
                                                    </div>
                                                </div>
                                                <div className="interaction-select">
                                                    <div className="select-container">
                                                        <div className="map-items">
                                                            <div className="text-item">
                                                                <PlaceAutocomplete className="text-item"
                                                                                   data-category="address"
                                                                                   name="address"
                                                                                   onPlaceSelect={setSelectedPlace}/>

                                                                {/*<input className="text-item" autoComplete="off"*/}
                                                                {/*       data-category="address" name="address" type="text"*/}
                                                                {/*       value="" id="q3-1"*/}
                                                                {/*       placeholder="Type your address here..."/>*/}
                                                            </div>
                                                            {/*<div id="canvas-1"><img src="https://i.imgur.com/sIaHZ9i.png"*/}
                                                            {/*                        alt=""/></div>*/}
                                                            <Map style={{height: '400px'}}
                                                                 mapId={"bf51a910020fa25a"}
                                                                 defaultZoom={20}
                                                                 mapTypeId={'satellite'}
                                                                 mapTypeControl={false}

                                                                 defaultCenter={{
                                                                     lat: 33.97377897043439,
                                                                     lng: -117.36946059046028
                                                                 }}
                                                                 gestureHandling={"greedy"}
                                                                 disableDefaultUI={false}
                                                            >
                                                                <AdvancedMarker draggable={true} onDragEnd={DragEnd}
                                                                                ref={markerRef} position={null}/>
                                                            </Map>
                                                            <MapHandler place={selectedPlace} marker={marker}/>

                                                            <div className="map-description">
                                                                <div className="google-cred"><i
                                                                    className="fa-brands fa-google"></i>powered by
                                                                    Google
                                                                </div>
                                                                <div className="drag-act">Drag the pin to your roof
                                                                </div>
                                                            </div>
                                                            <div className="map-info">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="interaction-movement">
                                                    <div className="interaction-back" onClick={event => moveBc(event)}>
                                                        <i
                                                            className="ri-arrow-left-s-line"></i></div>
                                                    <div
                                                        className={`interaction-next ${stepper.address === false ? 'interaction-out' : ''}`}
                                                        onClick={event => moveFw(event)}
                                                    >Next<i className="ri-arrow-right-s-line"></i></div>
                                                </div>
                                            </div>
                                            <div className="main-creds">
                                                <div className="main-cred">
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-enphase.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-lg.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-tesla.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-usc.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-ucr.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img
                                                            src="/assets/images/solarmax/solaredge-preferred-partner.png"
                                                            alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                    <div className="form-step">
                                        <section className="main-form-content">
                                            <div className="main-interaction">
                                                <div className="interaction-intro">
                                                    <div className="interaction-title">
                                                        What do you usually pay for electricity?
                                                    </div>
                                                    <div className="interaction-desc">
                                                        <div className="separate-icon"><i className="ri-sun-line"></i>
                                                        </div>
                                                        Energy costs are constantly climbing, but solar can make your
                                                        electricity bill virtual disappear.
                                                    </div>
                                                </div>
                                                <div className="interaction-select">
                                                    <div className="select-container">
                                                        <div className="range-item">
                                                            <label htmlFor="q7-1">Your average monthly electricity
                                                                bill?</label>
                                                            <input type="hidden" className="bill-count b-h-c"/>
                                                            <div
                                                                className="input-result input-result-q5-1 mobile">$ {formData.monthlyBill}</div>
                                                            <input className="range-button bill-count-range"
                                                                   onChange={event => handleFormInputs(event)}
                                                                   name="monthlyBill"
                                                                   type="range" value={formData.monthlyBill} id="q7-1"
                                                                   min="50" max="500"
                                                                   step="25"/>
                                                            <div
                                                                className="input-result input-result-q5-1">$ {formData.monthlyBill}</div>
                                                        </div>
                                                        <div className="range-item">
                                                            <label htmlFor="q7-2">What's your price
                                                                kilowatt-hour?</label>
                                                            <div className="input-result input-result-q5-2 mobile">$
                                                                0.30
                                                            </div>
                                                            <input className="range-button"
                                                                   onChange={event => handleFormInputs(event)}
                                                                   name="electricityRate" type="range"
                                                                   value={formData.electricityRate} id="q7-2" min="0"
                                                                   max="0.7" step="0.01"/>
                                                            <div
                                                                className="input-result input-result-q5-2">$ {formData.electricityRate}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="interaction-movement">
                                                    <div className="interaction-back" onClick={event => moveBc(event)}>
                                                        <i
                                                            className="ri-arrow-left-s-line"></i></div>
                                                    <div
                                                        className={`interaction-next ${stepper.type === false ? 'interaction-out' : ''}`}
                                                        onClick={event => moveFw(event)}
                                                    >Next<i className="ri-arrow-right-s-line"></i></div>
                                                </div>
                                            </div>
                                            <div className="main-creds">
                                                <div className="main-cred">
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-enphase.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-lg.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-tesla.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-usc.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-ucr.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img
                                                            src="/assets/images/solarmax/solaredge-preferred-partner.png"
                                                            alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="form-step">
                                        <section className="main-form-content">
                                            <div className="main-interaction">
                                                <div className="interaction-intro">
                                                    <div className="interaction-title">
                                                        Lastly...
                                                    </div>
                                                    <div className="interaction-subtitle">
                                                        <i className="fa-solid fa-circle"></i>Do you drive an electric
                                                        vehicle?
                                                    </div>
                                                    <div className="interaction-desc">
                                                        <div className="separate-icon"><i className="ri-sun-line"></i>
                                                        </div>
                                                        Driving an electric vehicle means you'll need to store power to
                                                        charge
                                                        it at night.
                                                    </div>
                                                </div>
                                                <div className="interaction-select">
                                                    <div className="select-container">
                                                        <div className="radio-item">
                                                            <label htmlFor="q5-1">Yes<input className="radio-button"
                                                                                            onClick={event => radioButtonHandle(event)}
                                                                                            data-category="ev_intro"
                                                                                            name="ev"
                                                                                            type="radio" value="yes"
                                                                                            id="q5-1"/></label>
                                                        </div>
                                                        <div className="radio-item">
                                                            <label htmlFor="q5-2">No<input className="radio-button"
                                                                                           name="ev"
                                                                                           onClick={event => radioButtonHandle(event)}
                                                                                           data-category="ev_intro"
                                                                                           type="radio"
                                                                                           value="no"
                                                                                           id="q5-2"/></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secondary-step">

                                                </div>

                                                <div className="interaction-movement">
                                                    <div className="interaction-back" onClick={event => moveBc(event)}>
                                                        <i
                                                            className="ri-arrow-left-s-line"></i></div>
                                                    {/*<div*/}
                                                    {/*    className={`interaction-next ${stepper.ev === false ? 'interaction-out' : ''}`}*/}
                                                    {/*    onClick={event => moveFw(event)}*/}
                                                    {/*>Get Quote<i className="ri-arrow-right-s-line"></i></div>*/}
                                                    <button id="submit" >Get Quote<i className="ri-arrow-right-s-line"></i></button>

                                                </div>
                                            </div>
                                            <div className="main-creds">
                                                <div className="main-cred">
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-enphase.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-lg.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-tesla.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-usc.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img src="/assets/images/solarmax/icon-ucr.png" alt=""/>
                                                    </div>
                                                    <div className="img-container">
                                                        <img
                                                            src="/assets/images/solarmax/solaredge-preferred-partner.png"
                                                            alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    {/*<div className="form-step">*/}
                                    {/*    <section className="main-form-content">*/}
                                    {/*        <div className="main-interaction">*/}
                                    {/*            <div className="interaction-intro">*/}
                                    {/*                <div className="interaction-title">*/}
                                    {/*                    <span>All Done!<i className="ri-sun-line"></i></span><br/><span>You can reduce your bill by <strong*/}
                                    {/*                    style={{*/}
                                    {/*                        fontSize: '35px',*/}
                                    {/*                        color: 'rgba(0,128,0,0.65)'*/}
                                    {/*                    }}>##%</strong> every month by going solar.</span>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="interaction-desc">*/}
                                    {/*                    <div className="separate-icon"><i className="ri-sun-line"></i>*/}
                                    {/*                    </div>*/}
                                    {/*                    Where should we send your detailed quote including the system*/}
                                    {/*                    size,*/}
                                    {/*                    guaranteed monthly production, cost and savings over time.*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}

                                    {/*            <div className="interaction-select">*/}
                                    {/*                <div className="select-container">*/}
                                    {/*                    <div className="map-items cred-items">*/}
                                    {/*                        <div className="cred-item">*/}
                                    {/*                            <i className="ri-user-line"></i><input*/}
                                    {/*                            onChange={event => handleFormInputs(event)}*/}
                                    {/*                            className="radio-button text-button" required*/}
                                    {/*                            autoComplete="off"*/}
                                    {/*                            data-category="firstName" name="firstName" type="text"*/}
                                    {/*                            value={formData.firstName}*/}
                                    {/*                            id="q11-1" placeholder="Type your first name"/>*/}
                                    {/*                        </div>*/}
                                    {/*                        <div className="cred-item">*/}
                                    {/*                            <i className="ri-user-settings-line"></i><input*/}
                                    {/*                            onChange={event => handleFormInputs(event)}*/}
                                    {/*                            className="radio-button text-button" required*/}
                                    {/*                            autoComplete="off"*/}
                                    {/*                            data-category="lastName" name="lastName" type="text"*/}
                                    {/*                            value={formData.lastName}*/}
                                    {/*                            id="q11-2" placeholder="Type your last name"/>*/}
                                    {/*                        </div>*/}
                                    {/*                        <div className="cred-item ">*/}
                                    {/*                            <i className="ri-mail-line"></i><input*/}
                                    {/*                            onChange={event => handleFormInputs(event)}*/}
                                    {/*                            className="radio-button text-button" required*/}
                                    {/*                            autoComplete="off"*/}
                                    {/*                            data-category="email" name="email" type="email"*/}
                                    {/*                            value={formData.email}*/}
                                    {/*                            id="q11-3" placeholder="Type your email address"/>*/}
                                    {/*                        </div>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="interaction-movement">*/}
                                    {/*                <button id="submit"><i className="ri-send-plane-fill"></i>Get*/}
                                    {/*                    personalized quote*/}
                                    {/*                </button>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="main-creds">*/}
                                    {/*            <div className="main-cred">*/}
                                    {/*                <div className="img-container">*/}
                                    {/*                    <img src="/assets/images/solarmax/icon-enphase.png" alt=""/>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="img-container">*/}
                                    {/*                    <img src="/assets/images/solarmax/icon-lg.png" alt=""/>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="img-container">*/}
                                    {/*                    <img src="/assets/images/solarmax/icon-tesla.png" alt=""/>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="img-container">*/}
                                    {/*                    <img src="/assets/images/solarmax/icon-usc.png" alt=""/>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="img-container">*/}
                                    {/*                    <img src="/assets/images/solarmax/icon-ucr.png" alt=""/>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="img-container">*/}
                                    {/*                    <img*/}
                                    {/*                        src="/assets/images/solarmax/solaredge-preferred-partner.png"*/}
                                    {/*                        alt=""/>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </section>*/}
                                    {/*</div>*/}
                                </div>
                            </Form>
                        </div>
                    </section>
                }
                {
                    status === 200 &&
                    <FormEstimateComplete handleModal={handleModal} calculationResult={calculationResult} debugValue={props.debugValue} modal={modal} checkButtonHandle={checkButtonHandle} handleFormInputs={handleFormInputs}
                                          formData={formData}/>
                }
                {status === 300 &&
                    <div className="container_s">
                        <div className="loader">
                            <span className="bar"></span>
                            <span className="bar"></span>
                            <span className="bar"></span>
                        </div>
                    </div>
                }
                {status === 400 &&
                    <div className="container_s" style={{maxWidth: "1440px", padding: "1rem 0"}}>
                        <div>
                            <span className="">Error in the estimation process.</span>
                        </div>
                    </div>
                }
        </APIProvider>
</>
)

}

export default FormEstimate;