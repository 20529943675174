import React from 'react';
import {Link} from "react-router-dom";
import Explore from "./shared/explore";
import Faq from "./shared/faq";
import {Helmet, HelmetProvider} from "react-helmet-async";

function SpecialOffers(props) {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Special Offers</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/special-offers`}/>
                </Helmet>
            </HelmetProvider>
            <section className="global__hero_single" style={{backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url(${require('../assets/media/pictures/static/special-offerc-hero.jpeg')})`}}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="single__hero_wrapper">
                        <div className="single__hero_text">
                            <div className="single__hero_title text-heading-3">Save with our latest special offers</div>
                            <div className="single__hero_desc  text-heading-5">Discover new discounts and deals and get updates on government incentives and rebates in our SolarMax newsletter</div>
                            <div className="single__hero_cta">
                                <button className={'text-heading-7'} onClick={props.toggleForm}>Subscribe now</button>
                            </div>
                        </div>
                    </div>
                </div><div></div>
            </section>
            <section className="special__offers">
                <div className="container_s">
                    <div className="special__offers_wrapper">
                        <div className="special__offers_title text-heading-5">Special offers from SolarMax</div>
                        <div className="special__offers_title text-body-1">Get the most out of going solar with our latest promotional offers</div>
                        <div className="special__offer_elements">
                            <div className="special__offer_element">
                                <div className="special__offer_img">
                                    <img src={require('../assets/media/pictures/static/offers/battery_offer-1.jpg')} alt=""/>
                                </div>
                                <div className="special__offer_text">
                                    <div className="special__offer_content">
                                    <div className="special__offer_title text-heading-5">Solar battery storage starting at $62/month</div>
                                    <div className="special__offer_desc text-body-1">Get more out of your home solar system with a solar battery for energy storage and rely even less on the grid.</div>
                                    </div>
                                    <div className="special__offer_cta text-heading-7"><Link onClick={props.handleLink} to={'/special-offers/battery'}>Learn more <i className="ri-arrow-right-line"></i></Link></div>
                                </div>
                            </div>
                            <div className="special__offer_element">
                                <div className="special__offer_img">
                                    <img src={require('../assets/media/pictures/static/offers/solar-offer-1.jpg')} alt=""/>
                                </div>
                                <div className="special__offer_text">
                                    <div className="special__offer_content">
                                    <div className="special__offer_title text-heading-5">Go solar for less than $40/month</div>
                                    <div className="special__offer_desc text-body-1">Enjoy our best deal on our premier panels & microinverters with systems starting as low as $39.83 per month!</div>
                                    </div>
                                    <div className="special__offer_cta text-heading-7"><Link onClick={props.handleLink} to={''}>Learn more <i className="ri-arrow-right-line"></i></Link></div>
                                </div>
                            </div>
                            <div className="special__offer_element">
                                <div className="special__offer_img">
                                    <img src={require('../assets/media/pictures/static/offers/refrral-offer.png')} alt=""/>
                                </div>
                                <div className="special__offer_text">
                                    <div className="special__offer_content">
                                        <div className="special__offer_title text-heading-5">Referral program bonus increased to $750</div>
                                        <div className="special__offer_desc text-body-1">We're increasing our referral incentive from $500 to $750. Act fast and refer your friends to get 50% bigger bonus this spring.</div>
                                    </div>
                                    <div className="special__offer_cta text-heading-7"><Link onClick={props.handleLink} to={'/referral'}>Learn more <i className="ri-arrow-right-line"></i></Link></div>
                                </div>
                            </div>
                            <div className="special__offer_element">
                                <div className="special__offer_img">
                                    <img src={require('../assets/media/pictures/static/offers/solar-tax.png')} alt=""/>
                                </div>
                                <div className="special__offer_text">
                                    <div className="special__offer_content">
                                        <div className="special__offer_title text-heading-5">Save 30% with solar tax
                                            credits
                                        </div>
                                        <div className="special__offer_desc text-body-1">Federal tax credits can take up
                                            to 30% off the total cost of your system. Lock your system today because
                                            these credits could change soon.
                                        </div>
                                    </div>
                                    {/*<div className="special__offer_cta text-heading-7"><a download href={'../assets/media/documents/tax-credit-brochure.pdf'}>Learn more <i className="ri-arrow-right-line"></i></a></div>*/}
                                    <div className="special__offer_cta text-heading-7"><Link onClick={props.handleLink} to={'/special-offers/solar-tax-credit'}>Learn
                                        more <i className="ri-arrow-right-line"></i></Link></div>
                                </div>
                            </div>

                            <div className="special__offer_element">
                                <div className="special__offer_img">
                                <img src={require('../assets/media/pictures/static/offers/financing-offer-1.jpg')} alt=""/>
                                </div>
                                <div className="special__offer_text">
                                    <div className="special__offer_content">
                                    <div className="special__offer_title text-heading-5">Save now with special financing</div>
                                    <div className="special__offer_desc text-body-1">See if you qualify for our special $0 Down, low-APR, 25-year financing with rates as low as 3.99%.</div>
                                    </div>
                                    <div className="special__offer_cta text-heading-7"><Link onClick={props.handleLink} to={'/special-offers/financing'}>Learn more <i className="ri-arrow-right-line"></i></Link></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Explore explore={props.explore}/>

            <Faq/>
        </>
    );
}

export default SpecialOffers;