import React, {useEffect, useState} from 'react';
import Global from "../shared/global";
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import '../../assets/css/home-solutions.css'
import {Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

function HomeOwnerSolar(props) {
    const [benefitIndex, setBenefitIndex] = useState('0')

    const [sliderValue, setSliderValue] = useState("260")

    const setDefaultSlider = (e) =>{
        let slider = document.getElementById('slider')
        let selector = document.getElementById('selector')
        let selectValue = document.getElementById('selectValue')
        let progressBar = document.getElementById('progressBar')

        selectValue.innerHTML = "$ "+slider.value
        selector.style.left = slider.value/10 + '%'
        progressBar.style.width = slider.value/10 + '%'
    }
    useEffect(()=>{
        setDefaultSlider()
    },[])
    const handleInput = (e) =>{
        setSliderValue(e.target.value)
        setDefaultSlider()
    }

    const handleSubmitCta = (e) => {
        e.preventDefault()
    }
    const handleBenefitChange = (e) =>{
        let index = e.target.dataset.benefit_index
        setBenefitIndex(index)
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Homeowners - Solar system</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/homeowners/solar`}/>
                </Helmet>
            </HelmetProvider>
            <section className="solutions__hero_light">
                <div className="">
                    <div className="solutions__hero_wrapper">
                        <div className="solutions__hero_pages">
                            <Splide aria-label="" hasTrack={false}
                                    options={{
                                        perPage: 1,
                                        arrows: false,
                                        type: 'loop',
                                        autoplay:true,
                                        resetProgress: false
                                    }}>
                                <SplideTrack>
                                    <SplideSlide style={{backgroundImage: `url(${require('../../assets/media/pictures/static/homesolution/1Hero.jpg')})`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                                        <div className="slide__wrapper container_s">
                                            <div className="slide__text">
                                                <div className="slide__sub text-heading-6">
                                                    Empowering families since 2008
                                                </div>
                                                <div className="slide__title text-heading-1">
                                                    Affordable energy independence
                                                </div>
                                                <div className="slide__desc text-heading-4">
                                                    Take back control of your power and cut your energy bill by 90% or more
                                                </div>
                                                <div className="slide__cta text-heading-6">
                                                    <button className=" text-heading-6" onClick={props.toggleForm}>Get a free quote</button>
                                                </div>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide style={{backgroundImage: `url('${require('../../assets/media/pictures/static/homesolution/1HB Hero.png')}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "80%"}}>
                                        <div className="slide__wrapper container_s">
                                            <div className="slide__text">
                                                <div className="slide__sub text-heading-6">
                                                    Empowering families and businesses since 2008
                                                </div>
                                                <div className="slide__title text-heading-1">
                                                    Keep powering on, no matter what
                                                </div>
                                                <div className="slide__desc text-heading-4">
                                                    Protect against grid failure and peak prices with a battery system
                                                </div>
                                                <div className="slide__cta text-heading-6">
                                                    <Link to={'/homeowners/battery'}>Learn more</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                    {/*<SplideSlide style={{backgroundImage: `url(${require('../../assets/media/pictures/static/homesolution/2Hero.jpg')})`, backgroundSize: `cover`, backgroundPosition:` ${props.bd.u_os === "desktop" ? 'center' : '65%' }`}}>*/}
                                    {/*    <div className="slide__wrapper container_s">*/}
                                    {/*        <div className="slide__text">*/}
                                    {/*            <div className="slide__sub text-heading-6">*/}
                                    {/*                Empowering families since 2008*/}
                                    {/*            </div>*/}
                                    {/*            <div className="slide__title text-heading-1">*/}
                                    {/*                Keep powering on, no matter what*/}
                                    {/*            </div>*/}
                                    {/*            <div className="slide__desc text-heading-4">*/}
                                    {/*                Protects against grid failure and peak prices with a battery system*/}
                                    {/*            </div>*/}
                                    {/*            <div className="slide__cta text-heading-6">*/}
                                    {/*                <Link to={'/businesses/solar'} onClick={props.handleLink}>Learn more</Link>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</SplideSlide>*/}
                                </SplideTrack>
                                <div className="splide__progress">
                                    <div className="splide__progress__bar"/>
                                </div>

                            </Splide>
                        </div>
                    </div>
                </div>
            </section>

            <section className="solution__intro_custom_light">
                <div className="container_s">
                    <div className="solution__intro_wrapper">
                        <div className="solution__intro_text">
                            <div className="solution__intro_title text-heading-4">Enjoy living green</div>
                            <div className="solution__intro_desc text-body-1">SolarMax has been freeing people from rising energy costs for nearly two decades, so we know a thing or two about how a state-of-the-art solar system can bring your bill down</div>
                        </div>
                        <div className="solution__intro_img">
                            <img src={require('../../assets/media/pictures/static/homesolution/2. Green living.jpg')} alt=""/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="solution__advantages_light">
                <div className="container_s">
                    <div className="solution__advantages_wrapper">
                        <div className="solution__advantages_text mb-3">
                            <div className="solution__advantages_title text-heading-4 mb-1">The power of generating your own electricity</div>
                            <div className="solution__advantages_desc text-body-1">Switching to solar energy brings a lot more than just power to your appliances</div>

                        </div>
                        <div className="solution__advantages_elements">
                            <div className="solution__advantages_element">
                                <div className="advantages__element_icon"><i className="ri-line-chart-line"></i></div>
                                <div className="advantages__element_title text-heading-6 mb-1">Compounding savings</div>
                                <div className="advantages__element_desc text-body-2">The amount you save on your monthly bill will keep growing as energy prices continue to rise</div>
                            </div>
                            <div className="solution__advantages_element">
                                <div className="advantages__element_icon"><i className="ri-service-line"></i></div>
                                <div className="advantages__element_title text-heading-6 mb-1">Increased property value</div>
                                <div className="advantages__element_desc text-body-2">Homes with solar systems sell faster and for higher market values than homes without solar</div>
                            </div>
                            <div className="solution__advantages_element">
                                <div className="advantages__element_icon"><i className="ri-money-dollar-box-line"></i></div>
                                <div className="advantages__element_title text-heading-6 mb-1">Eco-friendly living</div>
                                <div className="advantages__element_desc text-body-2">Minimize your carbon footprint by adopting clean energy and protect against future carbon taxes</div>
                            </div>
                            <div className="solution__advantages_element">
                                <div className="advantages__element_icon"><i className="ri-lightbulb-flash-line"></i></div>
                                <div className="advantages__element_title text-heading-6 mb-1">Energy independence</div>
                                <div className="advantages__element_desc text-body-2">Avoid outages and costly peak times with an affordable power storage system</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="solutions__benefits_custom">
                <div className="container_s">
                    <div className="solutions__benefits_custom_wrapper">
                        <div className="solutions__benefits_text">
                            <div className="solutions__benefits_title text-heading-4">5 easy steps to energy independence</div>
                            <div className="solutions__benefits_elements">
                                <div className={'benefits__progress'}></div>
                                <div data-benefit_index={'0'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '0' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'0'} className="benefits__element_num text-heading-6">1.</div>
                                    <div data-benefit_index={'0'} className="benefits__element_text">
                                        <div data-benefit_index={'0'} className="benefits__element_title text-heading-6">Personalized quotation</div>
                                        <div data-benefit_index={'0'}  className={`text-body-1 ${benefitIndex === '0' ? 'benefits__element_active' : 'benefits__element_hidden'} `}>
                                            Everyone's unique. We take the time to understand your exact needs, so your system covers them all.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '0' ? 'icon__active' : 'benefits__element_icon'}`}>
                                        <i data-benefit_index={'0'} className="ri-arrow-down-s-line "></i>
                                    </div>
                                </div>
                                <div data-benefit_index={'1'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '1' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'1'} className="benefits__element_num text-heading-6">2.</div>
                                    <div data-benefit_index={'1'} className="benefits__element_text">
                                        <div data-benefit_index={'1'} className="benefits__element_title text-heading-6">Detailed design</div>
                                        <div data-benefit_index={'1'}  className={`text-body-1 ${benefitIndex === '1' ? 'benefits__element_active' : 'benefits__element_hidden'} `}>
                                            Our engineers analyze your property, create a complete system blueprint, and gurantee how much power it will produce.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '1' ? 'icon__active' : 'benefits__element_icon'}`}>
                                        <i data-benefit_index={'1'}  className="ri-arrow-down-s-line"></i>
                                    </div>
                                </div>
                                <div data-benefit_index={'2'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '2' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'2'} className="benefits__element_num text-heading-6">3.</div>
                                    <div data-benefit_index={'2'} className="benefits__element_text">
                                        <div data-benefit_index={'2'} className="benefits__element_title text-heading-6">Expert installation</div>
                                        <div data-benefit_index={'2'}  className={`text-body-1 ${benefitIndex === '2' ? 'benefits__element_active' : 'benefits__element_hidden'} `}>
                                            Our team of certified professionals provide a swift, precise setup, ensuring your solar system operates at its best from day one.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '2' ? 'icon__active' : 'benefits__element_icon'}`}>
                                        <i data-benefit_index={'2'}  className="ri-arrow-down-s-line"></i>
                                    </div>
                                </div>
                                <div data-benefit_index={'3'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '3' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'3'} className="benefits__element_num text-heading-6">4.</div>
                                    <div data-benefit_index={'3'}className="benefits__element_text">
                                        <div data-benefit_index={'3'} className="benefits__element_title text-heading-6">Constant monitoring</div>
                                        <div data-benefit_index={'3'}  className={`text-body-1 ${benefitIndex === '3' ? 'benefits__element_active' : 'benefits__element_hidden'} `}>
                                            Check how much power is being generated and what you saved straight from our best-of-breed system tracking software.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '3' ? 'icon__active' : 'benefits__element_icon'}`}>
                                        <i data-benefit_index={'3'}  className="ri-arrow-down-s-line"></i>
                                    </div>
                                </div>
                                <div data-benefit_index={'4'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '4' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'4'} className="benefits__element_num text-heading-6">5.</div>
                                    <div data-benefit_index={'4'}  className={`benefits__element_text `}>
                                        <div data-benefit_index={'4'} className="benefits__element_title text-heading-6">After-sales service</div>
                                        <div data-benefit_index={'4'}  className={`text-body-1 ${benefitIndex === '4' ? 'benefits__element_active' : 'benefits__element_hidden'}`}>
                                            Enjoy continuous monitoring and dedicated support to keep your system in top performance.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '4' ? 'icon__active' : 'icon__inactive'}`}>
                                        <i data-benefit_index={'4'}  className="ri-arrow-down-s-line"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="solutions__benefits_img">
                            <img src={require(`../../assets/media/pictures/static/homesolution/4.${benefitIndex} HO_Steps.jpg`)} alt=""/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="roofing__options_cta">
                <div className="container_s">
                    <div className="roofing__options_cta_wrapper">
                        <div className="roofing__options_interact">
                            <div className="roofing__options_text">
                                <div className="roofing__cta_title text-heading-4 mb-2">
                                    Is your roof solar ready?
                                </div>
                                <div className="roofing__cta_desc text-body-1 mb-3">
                                    To maximize the life of your solar investment, your building needs to support for years to come
                                </div>
                                <div className="roofing__cta_home text-heading-6">
                                    <Link onClick={props.handleLink} to={'/roofing'}>Check our roof assessments</Link>
                                </div>
                            </div>
                            <div className="roofing__options_elements">
                                <div className="roofing__options_element">
                                    <div className="options__element_icon"><i className="ri-checkbox-circle-line"></i></div>
                                    <div className="options__element_text text-body-1">Solar installations require a durable roof. If your roof is over 15 years old, you might need to renovate it first</div>
                                </div>
                                <div className="roofing__options_element">
                                    <div className="options__element_icon"><i className="ri-checkbox-circle-line"></i></div>
                                    <div className="options__element_text text-body-1">Luckily, we provide in-house inspections and complete renovations to ensure the condition is optimal to last the entire life of the system</div>
                                </div>
                            </div>
                        </div>
                        <div className="roofing__options_img" style={{backgroundImage: `linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 15.46%), linear-gradient(270deg, rgba(255, 255, 255, 0.00) 88.47%, #FFF 100%), url(${require('../../assets/media/pictures/static/homesolution/5-Roofing.jpg')})`}}>
                            {/*<img src={require('../../assets/media/pictures/static/business/b-solar-roofing.png')} alt=""/>*/}
                        </div>

                    </div>
                </div>
            </section>

            <section className="credibility__global">
                <div className="container">
                    <div className="credibility__global_wrapper">
                        <div className="credibility__global_text">
                            <div className="credibility__global_title text-heading-4">
                                Our hassle-free service
                            </div>
                            {/*<div className="credibility__global_desc text-body-1">*/}
                            {/*    desc*/}
                            {/*</div>*/}
                        </div>

                        <div className="credibility__global_warranties">
                            <div className="credibility__warranties_img">
                                <img src={`${require('../../assets/media/pictures/static/global-credibility-1.png')}`} alt=""/>
                            </div>
                            <div className="credibility__warranties_text">
                                <div className="credibility__warranties_title text-heading-5 mb-1">
                                    Our work is solid, we guarantee it
                                </div>
                                <div className="credibility__warranties_desc text-body-1">
                                    Our workmanship warranties cover the system through it's 25-year life cycle, and our production guarantees ensure the system generates what we predict it will or we pay you!
                                </div>
                                <div className="credibility__sust_cta mt-2">
                                    <Link to={'/financing'}>Learn more about financing <i className="ri-arrow-right-line"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="credibility__global_sust">
                            <div className="credibility__sust_text">
                                <div className="credibility__sust_title text-heading-5 mb-1">
                                    Don’t break the bank!
                                </div>
                                <div className="credibility__sust_desc text-body-1">
                                    Get a system with our zero-down financing and rates as low as 3.99%* and reduce what you're paying for power right out of the gate.
                                </div>
                                <div className="credibility__sust_cta">
                                    <Link className={'mt-2'} to={'/warranties'}>Learn more about warranties <i className="ri-arrow-right-line"></i></Link>
                                </div>
                            </div>
                            <div className="credibility__sust_img">
                                <img src={`${require('../../assets/media/pictures/static/homesolution/6.1 Financing.png')}`} alt=""/>
                                <img src={`${require('../../assets/media/pictures/static/homesolution/6.2 Financing.png')}`} alt=""/>
                                <img src={`${require('../../assets/media/pictures/static/homesolution/6.3 Financing.png')}`} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={'rebates__global'}>
                <div className="container">
                    <div className="rebates__wrapper">
                        <div className="rebates__title text-heading-4" style={{fontWeight: "bold"}}>
                            Special offers
                        </div>
                        <div className="rebates__desc text-body-1">
                            <Link to={'/special-offers'}>View all our promotional offers <i className="ri-arrow-right-line"></i></Link>
                        </div>
                        <div className="rebates__offers">
                            <div className="rebates__offer">
                                <div className="rebates__offer_text">
                                    <div className="rebates__offer_title text-heading-3">
                                        Save up to 30% off
                                    </div>
                                    <div className="rebates__offer_desc text-body-1">
                                        Federal tax credits can take up to 30% off the total cost of your system. Lock your system today because these credits could change soon.
                                    </div>
                                    <div className="rebates__offer_cta">
                                        <a href="">Learn more</a>
                                    </div>

                                </div>
                                <div className="rebates__referral_picture">
                                    <img src={require('../../assets/media/pictures/static/homesolution/7.1-Tax-Credit.jpeg')} alt=""/>
                                </div>
                            </div>
                            <div className="rebates__offer_referral">
                                <div className="rebates__referral_text">
                                    <div className="rebates__offer_title text-heading-3">
                                        Go solar for less than $40/month
                                    </div>
                                    <div className="rebates__offer_desc text-body-1">
                                        Enjoy our best deal on our premier panels & microinverters with systems starting as low as $39.83 per month!
                                    </div>
                                    <div className="rebates__offer_cta">
                                        <Link to={'/special-offers'} onClick={props.handleLink}>Learn more</Link>
                                    </div>
                                </div>
                                <div className="rebates__referral_picture">
                                    <img src={require('../../assets/media/pictures/static/homesolution/7.2 40 deal image.jpg')} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="calculator__cta">
                <div className="container">
                    <div className="calculator__cta_wrapper">
                        <form className="calculator__cta_interact" method={'POST'} onSubmit={event => handleSubmitCta(event)}>
                            <div className="calculator__cta_title text-heading-3 mb-1">Unlock your savings potential</div>
                            <div className="calculator__cta_desc text-body-1">Solar calculator to assess and optimize your business energy <br/> savings.</div>
                            <div className="calculator__cta_input">
                                <input type="range" min="0" max="1000" value={sliderValue} id="slider" step={'10'} onInput={event =>handleInput(event) }/>
                                <div id="selector">
                                    <div className="selectBtn"></div>
                                    <div id="selectValue" className={"text-body-1"}></div>
                                </div>
                                <div id="progressBar"></div>
                                <div id="progressBackground"></div>
                                <div className="input__range"><span>$0</span><span>$1000</span></div>
                            </div>
                            <div className="calculator__cta_cta"><button className={'text-heading-7'} type="submit">Calculate</button></div>
                        </form>
                        <div className="calculator__cta_image">
                            <img src={`${require('../../assets/media/pictures/static/homesolution/8. Potential Savings.jpg')}`} alt=""/>
                        </div>
                    </div>
                </div>
            </section>

            <Global debugValue={props.debugValue}/>
        </>
    );
}

export default HomeOwnerSolar;