import React from 'react';

function LedLighting(props) {
    return (
        <div>
            <div className="container container-page">
                <div className="row">
                    <div className="col-md-6 format-page">


                        <div className="page-gallery">
                            <div className="row mb-2">
                                <div className="col-xs-12">
                                    <div className="page-gallery-thumbnail page-gallery-hero">
                                        <a href="/assets/media/pictures/wwwsolarmaxtechcom/gallery/original/5a8a80df-97bc-4b3c-9494-bddf2cd33041.jpg"
                                           data-bsgallery="Commercial Solutions: LED Lighting"
                                           data-galleryid="818898844399376"
                                           data-itemid="818898844399376-818880844641720" data-description=""
                                           data-gallerytype="image"
                                           data-thumb-src="/assets/media/pictures/wwwsolarmaxtechcom/gallery/thumbs/5a8a80df-97bc-4b3c-9494-bddf2cd33041.jpg"
                                           data-backdrop="static">
                                            <img className=""
                                                 src="/assets/media/pictures/wwwsolarmaxtechcom/gallery/medium/5a8a80df-97bc-4b3c-9494-bddf2cd33041.jpg"
                                                 alt="Commercial Solutions: LED Lighting Photo 1"/>
                                            <div className="label-overlay">
                                            </div>
                                        </a>
                                    </div>
                                </div>


                                <div className="col-xs-4">
                                    <div className="page-gallery-thumbnail">
                                        <a href="/assets/media/pictures/wwwsolarmaxtechcom/gallery/original/4e63b2f4-4df6-4eb2-941b-503de84d4142.jpg"
                                           data-bsgallery="Commercial Solutions: LED Lighting"
                                           data-galleryid="818898844399376"
                                           data-itemid="818898844399376-818880844641664" data-description=""
                                           data-gallerytype="image"
                                           data-thumb-src="/assets/media/pictures/wwwsolarmaxtechcom/gallery/thumbs/4e63b2f4-4df6-4eb2-941b-503de84d4142.jpg"
                                           data-backdrop="static">
                                            <img className=""
                                                 src="/assets/media/pictures/wwwsolarmaxtechcom/gallery/thumbs/4e63b2f4-4df6-4eb2-941b-503de84d4142.jpg"
                                                 alt="Commercial Solutions: LED Lighting Photo 2"/>

                                            <div className="label-overlay">
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xs-4">
                                    <div className="page-gallery-thumbnail">
                                        <a href="/assets/media/pictures/wwwsolarmaxtechcom/gallery/original/2b6da17d-b635-4172-9a25-f737600b8984.jpg"
                                           data-bsgallery="Commercial Solutions: LED Lighting"
                                           data-galleryid="818898844399376"
                                           data-itemid="818898844399376-818880844641617" data-description=""
                                           data-gallerytype="image"
                                           data-thumb-src="/assets/media/pictures/wwwsolarmaxtechcom/gallery/thumbs/2b6da17d-b635-4172-9a25-f737600b8984.jpg"
                                           data-backdrop="static">
                                            <img className=""
                                                 src="/assets/media/pictures/wwwsolarmaxtechcom/gallery/thumbs/2b6da17d-b635-4172-9a25-f737600b8984.jpg"
                                                 alt="Commercial Solutions: LED Lighting Photo 3"/>

                                            <div className="label-overlay">
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xs-4">
                                    <div className="page-gallery-thumbnail">
                                        <a href="/assets/media/pictures/wwwsolarmaxtechcom/gallery/original/144a6007-0607-4a83-ab46-d6a75d518c60.jpg"
                                           data-bsgallery="Commercial Solutions: LED Lighting"
                                           data-galleryid="818898844399376"
                                           data-itemid="818898844399376-818880844641570" data-description=""
                                           data-gallerytype="image"
                                           data-thumb-src="/assets/media/pictures/wwwsolarmaxtechcom/gallery/thumbs/144a6007-0607-4a83-ab46-d6a75d518c60.jpg"
                                           data-backdrop="static">
                                            <img className=""
                                                 src="/assets/media/pictures/wwwsolarmaxtechcom/gallery/thumbs/144a6007-0607-4a83-ab46-d6a75d518c60.jpg"
                                                 alt="Commercial Solutions: LED Lighting Photo 4"/>

                                            <div className="label-overlay">
                                            </div>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="bs-gallery" data-galleryid="818898844399376" tabIndex="1">
                            <div className="modal">
                                <div className="modal-dialog">
                                    <a href="#" data-toggle="collapse" data-target=".modal-dock" aria-expanded="false"
                                       aria-controls="modal-dock" className="modal-dock-overlay">
                                        <span className="sr-only">Enlarge Image</span>
                                    </a>
                                    <div className="row modal-row">
                                        <div className="col-md-9 modal-col modal-col-canvas">
                                            <div className="modal-canvas-body">
                                                <div className="overlay-label"></div>
                                                <a href="#" className="btn btn-close" data-dismiss="modal"
                                                   aria-hidden="true">
                                                    <span className="sr-only">Close</span>
                                                    <i className="fal fa-times" aria-hidden="true"></i>
                                                </a>
                                                <div className="modal-canvas">
                                                    <div className="modal-media">
                                                        <span className="modal-media-helper"></span>
                                                    </div>
                                                </div>
                                                <div className="modal-controls">
                                                    <button type="button" className="btn btn-link btn-prev">
                                                        <span className="sr-only">Previous Image</span>
                                                        <i className="fal fa-chevron-left fa-fw" aria-hidden="true"></i>
                                                    </button>
                                                    <button type="button" className="btn btn-link btn-thumbnails"
                                                            data-toggle="collapse" data-target=".modal-dock"
                                                            aria-expanded="false" aria-controls="modal-dock">
                                                        <span className="sr-only">All Images</span>
                                                        <i className="fas fa-th fa-fw" aria-hidden="true"></i>
                                                    </button>
                                                    <button type="button" className="btn btn-link btn-next">
                                                        <span className="sr-only">Next Image</span>
                                                        <i className="fal fa-chevron-right fa-fw"
                                                           aria-hidden="true"></i>
                                                    </button>
                                                    <span className="media-count"><span
                                                        className="current-count count">1</span><small
                                                        className="text-muted count">of</small><span
                                                        className="total-count count">1</span></span>
                                                </div>
                                                <div className="modal-dock collapse">
                                                    <div className="dock-title">
                                                        <button type="button" className="btn btn-link btn-close"
                                                                data-toggle="collapse" data-target=".modal-dock"
                                                                aria-expanded="false" aria-controls="modal-dock">
                                                            <span className="sr-only">Close</span>
                                                            <i className="fal fa-times" aria-hidden="true"></i>
                                                        </button>
                                                        <span className="gallery-label gallery-title ellipsis"></span>
                                                    </div>
                                                    <div className="modal-thumbnails">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 modal-col modal-col-content">
                                            <div className="modal-content">
                                                <span className="gallery-label gallery-title"></span>
                                                <a className="original-img-link" href="#" target="_blank" rel="noreferrer">
                                                    View Original Image
                                                    <i className="fas fa-external-link mr-2-left"
                                                       aria-hidden="true"></i>
                                                </a>
                                                <p className="modal-title"></p>
                                                <p className="modal-caption"></p>
                                            </div>
                                            <div className="bs-gallery-btn-group-share">
                                                <p className="gallery-label">Share This</p>
                                                <a className="btn btn-link" rel="noreferrer"
                                                   href="https://www.facebook.com/sharer/sharer.php?u=https%3a%2f%2fwww.solarmaxtech.com%2fcommercial%2fled-lighting%2f"
                                                   target="_blank">
                                                    <span className="sr-only">Facebook</span>
                                                    <i className="fab fa-facebook-f fa-fw" aria-hidden="true"></i>
                                                </a>
                                                <a className="btn btn-link" rel="noreferrer"
                                                   href="https://twitter.com/home?status=Thought%20you%20might%20like%20this https%3a%2f%2fwww.solarmaxtech.com%2fcommercial%2fled-lighting%2f"
                                                   target="_blank">
                                                    <span className="sr-only">Twitter</span>
                                                    <i className="fab fa-twitter fa-fw" aria-hidden="true"></i>
                                                </a>
                                                <a className="btn btn-link" rel="noreferrer"
                                                   href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3a%2f%2fwww.solarmaxtech.com%2fcommercial%2fled-lighting%2f"
                                                   target="_blank">
                                                    <span className="sr-only">LinkedIn</span>
                                                    <i className="fab fa-linkedin-in fa-fw" aria-hidden="true"></i>
                                                </a>
                                                <a className="btn btn-link"
                                                   href="https://pinterest.com/pin/create/button/?url=https%3a%2f%2fwww.solarmaxtech.com%2fcommercial%2fled-lighting%2f&amp;media=#MEDIA#"
                                                   target="_blank" rel="noreferrer">
                                                    <span className="sr-only">Pinterest</span>
                                                    <i className="fab fa-pinterest-p fa-fw" aria-hidden="true"></i>
                                                </a>
                                                <a className="btn btn-link" rel="noreferrer"
                                                   href="mailto:?subject=Thought%20you%20might%20like%20this%20from SolarMax%20Technology%20&amp;body=Check%20this%20out%20from SolarMax%20Technology https%3a%2f%2fwww.solarmaxtech.com%2fcommercial%2fled-lighting%2f"
                                                   target="_blank">
                                                    <span className="sr-only">Email</span>
                                                    <i className="fas fa-envelope fa-fw" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <script defer="" src="/js/bundle/bundle.ui.gallery.min.js?v=17.0.5.1"></script>


                        <h1>California Commercial LED Lighting</h1>

                        <div className="page-content">


                            <p><a href="https://www.solarmaxled.com/" className="btn btn-block btn-outline-secondary"
                                  target="_blank" rel="nofollow">Learn More About Solar Max LED</a></p> <p>When
                            implemented as part of a well-designed lighting system, commercial LED lighting can reduce
                            your organization’s energy footprint while also providing a reliable and affordable source
                            of light. Whether you’re illuminating a warehouse production facility, an outdoor sports
                            complex, or the company parking lot, SolarMax Technology has one of the best selections of
                            commercial LED lighting in California—and we’d love to help you save energy, cut operating
                            costs, and go green!</p> <h2>Energy Saving Lighting Solutions for Your Business</h2> <p>In
                            large commercial enterprises, even a seemingly small upgrade—like switching from dated
                            halogen bulbs to more modern LED lighting—can result in significant benefits to your
                            organization. At SolarMax, we’ll work closely with you to choose the right type of
                            commercial LED lighting system so that you can enjoy the greatest advantages:</p>
                            <ul>
                                <li><strong>Energy Savings</strong>: ENERGY STAR-certified LED lighting can lower energy
                                    costs without compromising on light quality, and comes in varied designs like strip
                                    lighting, bay lighting, flat panels, and more.<p></p><p></p></li>
                                <li><strong>Reduced Maintenance</strong>: LED lighting has a much longer expected
                                    lifetime, which reduces the need for bulb replacement and can save a lot of labor
                                    when you have spacious facilities. <p></p><p></p></li>
                                <li><strong>Improved Workplace Performance</strong>: The right lighting can make your
                                    employees feel more comfortable, enhance productivity and output, and provide safe
                                    illumination in emergency situations.<p></p><p></p></li>
                                <li><strong>Warranty Coverage</strong>: Our energy saving lighting installations and
                                    other commercial solutions come backed by 25-year limited workmanship warranties.
                                </li>
                            </ul>
                            <h3>Request a Free Commercial LED Lighting System Design Consultation </h3> <p>If you’re
                            interested in learning more about our <a href="/commercial/">energy-saving commercial
                                solutions</a>—whether for commercial LED lighting, energy storage, or solar panels—don’t
                            hesitate to reach out to SolarMax today! We’d be happy to meet with you for a one-on-one
                            consultation, or we can host a free Lunch-n-Learn session to share the many benefits of
                            solar energy with your entire staff. We want you to have all the information you need to
                            make the right decision for your business, so you can expect a detailed, helpful approach
                            with absolutely no sales pressure.</p> <p>Ready to find out more? Call us to speak with a
                            local expert in energy saving lighting for California businesses, or submit our online form
                            to get started with your free cost estimate now.</p>
                        </div>


                        <div className="page-nav-btn-group">
                            <h2>Explore Options</h2>
                            <a className="btn btn-page btn-info btn-custom" href="/commercial/energy-storage/">Energy
                                Storage</a>
                            <a className="btn btn-page btn-info btn-custom" href="/commercial/home-builders/">Home
                                Builders</a>
                            <a className="btn btn-page btn-info btn-custom active" href="/commercial/led-lighting/">SolarMax
                                LED Lighting</a>
                            <a className="btn btn-page btn-info btn-custom" href="/commercial/solar-energy/">Commercial
                                Solar Energy</a>
                        </div>

                    </div>
                    <div className="col-md-6 sticky-sidebar">

                        <iframe className={'form__holder'} style={{width: '100%', height: '670px'}} id={'solarmax__form'} src="https://4345356.extforms.netsuite.com/app/site/crm/externalleadpage.nl?compid=4345356&formid=41&h=AAFdikaIVOFmEcL6X0HNZv561ucz6phoJ3CcDdjwDZ5szkC1Eog" frameBorder="0"></iframe>

                        <div className="offer-panel text-center">
                            <div className="panel panel-default">
                                <div className="panel-hero">
                                    <a href="/offers/get-a-free-solar-analysis/">
                                        <img className="img-responsive"
                                             src="/assets/media/pictures/wwwsolarmaxtechcom/offers/images/medium/55d16929-22c7-4c11-85b5-3ae1cd33acb2.jpg"
                                             alt="Get a Free Solar Analysis!"/>
                                    </a>
                                </div>
                                <div className="panel-body">
                                    <a href="/offers/get-a-free-solar-analysis/">
                                        <p className="offer-title h4">Get a Free Solar Analysis!</p>
                                    </a>
                                    <p>If your business is looking to save money on energy bills, let SolarMax help you
                                        achieve your goals!</p>
                                    <p>
                                        <a href="#" className="share-offer" data-toggle="modal"
                                           data-target="#offer-a160beb4-e2c8-44ed-8e01-8436efafa59a"
                                           data-backdrop="static">Share</a>
                                    </p>
                                </div>
                                <div className="panel-footer">
                                    <a className="btn btn-info btn-block btn-lg btn-custom"
                                       href="/offers/get-a-free-solar-analysis/">Get Offer</a>
                                </div>
                            </div>
                        </div>

                        <div id="offer-a160beb4-e2c8-44ed-8e01-8436efafa59a"
                             className="modal fade modal-share text-center share-a160beb4-e2c8-44ed-8e01-8436efafa59a">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <p className="modal-title">Get a Free Solar Analysis!</p>
                                    </div>
                                    <div className="modal-body">
                                        <span className="modal-label">Share</span>
                                        <div className="btn-group-social-media">
                                            <a className="btn btn-facebook"
                                               href="https://www.facebook.com/sharer/sharer.php?u=http%3a%2f%2fwww.solarmaxtech.com%2foffers%2fget-a-free-solar-analysis%2f"
                                               target="_blank">
                                                <span className="sr-only">Share on Facebook</span>
                                                <i className="fab fa-facebook-f fa-fw" aria-hidden="true"></i>
                                            </a>
                                            <a className="btn btn-twitter"
                                               href="https://twitter.com/home?status=Thought%20you%20might%20like%20this http%3a%2f%2fwww.solarmaxtech.com%2foffers%2fget-a-free-solar-analysis%2f"
                                               target="_blank">
                                                <span className="sr-only">Share on Twitter</span>
                                                <i className="fab fa-twitter fa-fw" aria-hidden="true"></i>
                                            </a>
                                            <a className="btn btn-linkedin"
                                               href="https://www.linkedin.com/shareArticle?mini=true&amp;url=http%3a%2f%2fwww.solarmaxtech.com%2foffers%2fget-a-free-solar-analysis%2f"
                                               target="_blank">
                                                <span className="sr-only">Share on LinkedIn</span>
                                                <i className="fab fa-linkedin-in fa-fw" aria-hidden="true"></i>
                                            </a>
                                            <a className="btn btn-pinterest"
                                               href="https://pinterest.com/pin/create/button/?url=http%3a%2f%2fwww.solarmaxtech.com%2foffers%2fget-a-free-solar-analysis%2f&amp;media=https%3a%2f%2fcmsplatform.blob.core.windows.net%2fwwwsolarmaxtechcom%2foffers%2fimages%2flarge%2f55d16929-22c7-4c11-85b5-3ae1cd33acb2.jpg&amp;description=Get a Free Solar Analysis!"
                                               target="_blank">
                                                <span className="sr-only">Share on Pinterest</span>
                                                <i className="fab fa-pinterest-p fa-fw" aria-hidden="true"></i>
                                            </a>
                                            <a className="btn btn-warning"
                                               href="mailto:?subject=Thought%20you%20might%20like%20this%20from SolarMax%20Technology%20&amp;body=Check%20this%20out%20from SolarMax%20Technology Get a Free Solar Analysis! http%3a%2f%2fwww.solarmaxtech.com%2foffers%2fget-a-free-solar-analysis%2f"
                                               target="_blank">
                                                <span className="sr-only">Share via Email</span>
                                                <i className="fas fa-envelope fa-fw" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline btn-modal-share-close"
                                                data-dismiss="modal">Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="banner-areas-served panel">
                            <div className="panel-body">
                                <p className="h2 text-center">Areas We Serve</p>
                                <div className="row city-list">

                                    <div className="col-xs-6 match-height" style={{height: "28px"}}>
                                        <a href="/anaheim/"><i className="fas fa-map-marker-alt mr-2"
                                                               aria-hidden="true"></i>Anaheim</a>
                                    </div>
                                    <div className="col-xs-6 match-height" style={{height: "28px"}}>
                                        <a href="/solar-panels/arcadia/"><i className="fas fa-map-marker-alt mr-2"
                                                                            aria-hidden="true"></i>Arcadia</a>
                                    </div>
                                    <div className="col-xs-6 match-height" style={{height: "28px"}}>
                                        <a href="/solar-panels/chino-hills/"><i className="fas fa-map-marker-alt mr-2"
                                                                                aria-hidden="true"></i>Chino Hills</a>
                                    </div>
                                    <div className="col-xs-6 match-height" style={{height: "28px"}}>
                                        <a href="/corona/"><i className="fas fa-map-marker-alt mr-2"
                                                              aria-hidden="true"></i>Corona</a>
                                    </div>
                                    <div className="col-xs-6 match-height" style={{height: "28px"}}>
                                        <a href="/solar-panels/diamond-bar/"><i className="fas fa-map-marker-alt mr-2"
                                                                                aria-hidden="true"></i>Diamond Bar</a>
                                    </div>
                                    <div className="col-xs-6 match-height" style={{height: "28px"}}>
                                        <a href="/fontana/"><i className="fas fa-map-marker-alt mr-2"
                                                               aria-hidden="true"></i>Fontana</a>
                                    </div>
                                    <div className="col-xs-6 match-height" style={{height: "28px"}}>
                                        <a href="/solar-panels/hacienda-heights/"><i
                                            className="fas fa-map-marker-alt mr-2" aria-hidden="true"></i>Hacienda
                                            Heights</a>
                                    </div>
                                    <div className="col-xs-6 match-height" style={{height: "28px"}}>
                                        <a href="/solar-panels/hemet/"><i className="fas fa-map-marker-alt mr-2"
                                                                          aria-hidden="true"></i>Hemet</a>
                                    </div>
                                    <div className="col-xs-6 match-height" style={{height: "28px"}}>
                                        <a href="/irvine/"><i className="fas fa-map-marker-alt mr-2"
                                                              aria-hidden="true"></i>Irvine</a>
                                    </div>
                                    <div className="col-xs-6 match-height" style={{height: "28px"}}>
                                        <a href="/solar-panels/long-beach/"><i className="fas fa-map-marker-alt mr-2"
                                                                               aria-hidden="true"></i>Long Beach</a>
                                    </div>
                                    <div className="col-xs-6 match-height" style={{height: "28px"}}>
                                        <a href="/los-angeles/"><i className="fas fa-map-marker-alt mr-2"
                                                                   aria-hidden="true"></i>Los Angeles</a>
                                    </div>
                                    <div className="col-xs-6 match-height" style={{height: "28px"}}>
                                        <a href="/solar-panels/menifee/"><i className="fas fa-map-marker-alt mr-2"
                                                                            aria-hidden="true"></i>Menifee</a>
                                    </div>

                                </div>
                                <div className="text-center">
                                    <a className="btn btn-outline-white btn-custom" href="/solar-panels/">View All
                                        Service Areas</a>
                                </div>
                            </div>
                        </div>


                        <div className="company-blog panel panel-default">
                            <div className="panel-body p-4">
                                <article>
                                    <div className="mb-2">
                                        <header className="post-header h3 my-0">
                                            <a href="/blog/p.240722000/solar-energy-solutions-for-california-commercial-properties/">Solar
                                                Energy Solutions for California Commercial Properties</a>
                                        </header>
                                    </div>
                                    <p className="post-entry">When it comes to solar, most individuals only picture
                                        residential installations. While this is certainly an avenue, commercial solar
                                        is just as important. If you own a commercial...</p>
                                    <a href="/blog/p.240722000/solar-energy-solutions-for-california-commercial-properties/"
                                       className="post-continue">Continue Reading<i
                                        className="far fa-chevron-right ml-2" aria-hidden="true"></i></a>
                                </article>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default LedLighting;
