import React from 'react';
import {Form, Link} from "react-router-dom";

const FormEstimateComplete = (props) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        // if (props.formData.interests.length < 1) {
        //     alert('Please choose at least one of the options. Cash, Loan or Lease.')
        // }
        if (props.formData.daytime.length < 1) {
            alert('Please choose at least one of the time options.')
        } else if (!props.formData.phone) {
            alert('Please add phone number.')
        } else {
            fetch(`${props.debugValue}/api/schedule-call`, {
                method: "POST",
                body: JSON.stringify(props.formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(data => {
                console.log(data)
                if (data.status === 200) {
                    alert('Successfully scheduled.')
                    window.location.href = ('/');
                } else {
                    alert('Something went wrong! Please refresh the page and try again.')
                }
            })
        }

    }

    const handleReportSubmit = (e) => {
        e.preventDefault();

        if (props.formData.firstName.length < 1 || props.formData.lastName.length < 1 || props.formData.email.length < 1) {
            alert('Please fill all the required fields.')
        }else {
            fetch(`${props.debugValue}/api/form-estimated-user`, {
                method: "POST",
                body: JSON.stringify(props.formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(data => {
                console.log(data)
                if (data.status === 200) {
                    alert('Successfully sent.')
                } else if (data.status === 401) {
                    alert('Failed to send the message. Please try again.')
                } else if (data.status === 400) {
                    alert('Something went wrong! Please refresh the page and try again.')
                }
            })
        }

    }


    return (
        <>
            <section id="result">
                <div className="result-wrapper">
                    {/*<div className="result-title">*/}
                    {/*    Thank you {props.formData ? props.formData.firstName+' !' : ' !'} Please check your email for a detailed quote.*/}
                    {/*</div>*/}
                    <div className="result-container">
                        {
                            <>
                                <div className="option-container result-cash-container">
                                    <div className="cash-title">Pay Cash to save</div>
                                    <div
                                        className="cash-ppe">{props.calculationResult && `${props.calculationResult.cash_save} $`}</div>
                                    <div className="cash-description">Own the system; maximize savings<br/>Pay for a
                                        turnkey
                                        system; Government incentives cover 30% - 65% of the cost.
                                    </div>
                                    <div className="cash-saving">
                                        <div className="value"></div>
                                        <div className="item-desc">25-Year Net Savings</div>
                                    </div>
                                    {/*<div className="cash-net-cost">*/}
                                    {/*    <div className="value"></div>*/}
                                    {/*    <div className="item-desc">Net Cost</div>*/}
                                    {/*</div>*/}

                                    <div className="cash-ppe">
                                        <div className="value">3% or more</div>
                                        <div className="item-desc">Increase in Property Value</div>
                                    </div>
                                    <div className="cash-ppe">
                                        <div className="value">Get 30%</div>
                                        <div className="item-desc">Back in Tax Credit</div>
                                    </div>
                                    <div className="saving-type">CASH</div>
                                    {/*<div className="interest-container"><input*/}
                                    {/*    data-category="interests"*/}
                                    {/*    onClick={event => props.checkButtonHandle(event)}*/}
                                    {/*    type="checkbox" name="interest"*/}
                                    {/*    className="interest-check"*/}
                                    {/*    id="cash-interest"*/}
                                    {/*    value="cash"/><label*/}
                                    {/*    htmlFor="cash-interest">I'm interested</label></div>*/}
                                    <strong></strong>
                                </div>

                                <div className="option-container result-loan-container">
                                    <div className="loan-title">$0-Down Loan to save</div>
                                    <div className="loan-ppe">{props.calculationResult && `${props.calculationResult.loan_save} $`}</div>
                                    <div className="loan-description">Own the system; no up-front cost<br/>Qualify for
                                        government incentives; Interest may be tax-deductible.
                                    </div>
                                    <div className="loan-saving">
                                        <div className="value"></div>
                                        <div className="item-desc">25-Year Net Savings</div>
                                    </div>
                                    {/*<div className="loan-payment">*/}
                                    {/*    <div className="value"></div>*/}
                                    {/*    <div className="item-desc">Annual Loan</div>*/}
                                    {/*</div>*/}
                                    <div className="loan-ppe">
                                        <div className="value">3.9%</div>
                                        <div className="item-desc">Interest Rate</div>
                                    </div>
                                    <div className="loan-ppe">
                                        <div className="value"></div>
                                        3% or more
                                        <div className="item-desc">Increase in Property Value</div>
                                    </div>
                                    <div className="saving-type">LOAN</div>
                                    {/*<div className="interest-container"><input*/}
                                    {/*    data-category="interests"*/}
                                    {/*    onClick={event => props.checkButtonHandle(event)}*/}
                                    {/*    type="checkbox" name="interest"*/}
                                    {/*    className="interest-check"*/}
                                    {/*    id="loan-interest"*/}
                                    {/*    value="loan"/><label*/}
                                    {/*    htmlFor="loan-interest">I'm interested</label></div>*/}
                                    <strong></strong>
                                </div>

                                <div className="option-container result-lease-container">
                                    <div className="lease-title">$0-Down Lease to save</div>
                                    <div className="lease-ppe">{props.calculationResult && props.calculationResult.lease_save}</div>
                                    <div className="lease-description">Rent the system; no up-front cost<br/>Solar
                                        company
                                        owns and maintains the system; buy electricity at a discount.
                                    </div>
                                    <div className="lease-saving">
                                        <div className="value"></div>
                                        <div className="item-desc">25-Year Net Savings</div>
                                    </div>
                                    {/*<div className="lease-payment">*/}
                                    {/*    <div className="value"></div>*/}
                                    {/*    <div className="item-desc">Annual Lease</div>*/}
                                    {/*</div>*/}
                                    <div className="lease-ppe">
                                        <div className="value">Immediate</div>
                                        <div className="item-desc">Payback</div>
                                    </div>
                                    <div className="lease-ppe">
                                        <div className="value">$ 0 Down</div>
                                        <div className="item-desc">No up-front costs</div>
                                    </div>
                                    <div className="saving-type">LEASE</div>
                                    {/*<div className="interest-container"><input*/}
                                    {/*    data-category="interests"*/}
                                    {/*    onClick={event => props.checkButtonHandle(event)}*/}
                                    {/*    type="checkbox" name="interest"*/}
                                    {/*    className="interest-check"*/}
                                    {/*    id="lease-interest"*/}
                                    {/*    value="lease"/><label*/}
                                    {/*    htmlFor="lease-interest">I'm interested</label></div>*/}
                                    <strong></strong>
                                </div>
                            </>

                        }


                        <div className={'col-lg-12 p-0'}>
                            <div className="result-total-container w-100 d-flex w-100 justify-space-between">
                                <div className={'mt-4 col-lg-5 col-md-5 col-12'}>
                                    <div className="total-title">Your report is ready</div>
                                    <div className="total-saving"></div>

                                    <div className="total-title phone-title">Send it to email</div>

                                    <div className="phone-submit" data-modal={'report'}
                                         onClick={event => props.handleModal(event)}
                                         style={{userSelect: "none", cursor: "pointer"}}><i
                                        className="fa-solid fa-calendar-days"></i>Get my report
                                    </div>
                                    <p className={'text-body-3'}>We won't share your email with anyone, and you can
                                        unsubscribe at any time. Learn more in our <Link
                                            style={{color: 'var(--branding-secondary)'}} to={'/privacy'}>privacy
                                            policy</Link>.</p>

                                </div>
                                <div className={"mt-4 col-lg-5 col-md-5 col-12 "}>
                                    <div className="total-title">Talk to professionals</div>
                                    <div className="total-saving"></div>

                                    <div className="total-title phone-title">Ready for the consultation?</div>

                                    <div className="phone-submit" data-modal={'call'}
                                         onClick={event => props.handleModal(event)}
                                         style={{userSelect: "none", cursor: "pointer"}}><i
                                        className="fa-solid fa-calendar-days"></i>Schedule
                                        call
                                    </div>
                                    <p className={'text-body-3'}>We won't share your email with anyone, and you can
                                        unsubscribe at any time. Learn more in our <Link
                                            style={{color: 'var(--branding-secondary)'}} to={'/privacy'}>privacy
                                            policy</Link>.</p>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                <div className="calendar-modal" style={{
                    opacity: (props.modal.modal && props.modal.type === "call") ? 1 : 0,
                    transition: '.3s',
                    visibility: (props.modal.modal && props.modal.type === "call") ? 'visible' : 'hidden'
                }}>
                    <Form onSubmit={event => handleSubmit(event)} id="additional-info" className=""  style={{padding: '0 !important'}}>

                        <div className="calendar-wrapper" onClick={props.handleModal}>

                        </div>
                        <div className="calendar-container">
                            <div className="calendar-intro d-flex justify-content-between">
                                Choose your preferred date / time for consultation<i id="close-modal"
                                                                                     className="fa-solid fa-xmark"></i>
                            </div>
                            <div className="calendar-date">
                                <input required type="date" name={'date'} placeholder="" className="datepicker"
                                       value={props.formData.date}
                                       onChange={event => props.handleFormInputs(event)}/>
                            </div>
                            <div className="calendar-daytime select-container">
                                <div className="select-item">
                                    <label htmlFor="q12-1">9:00-12:00<input data-category="daytime" name="daytime"
                                                                            className="check-button daytime-check"
                                                                            onClick={event => props.checkButtonHandle(event)}
                                                                            type="checkbox" value="9:00-12:00"
                                                                            id="q12-1"/></label>
                                </div>
                                <div className="select-item">
                                    <label htmlFor="q12-2">12:00-1:00<input data-category="daytime" name="daytime"
                                                                            className="check-button daytime-check"
                                                                            onClick={event => props.checkButtonHandle(event)}
                                                                            type="checkbox" value="12:00-1:00"
                                                                            id="q12-2"/></label>
                                </div>
                                <div className="select-item">
                                    <label htmlFor="q12-3">01:00-2:00<input data-category="daytime" name="daytime"
                                                                            className="check-button daytime-check"
                                                                            onClick={event => props.checkButtonHandle(event)}
                                                                            type="checkbox" value="1:00-2:00"
                                                                            id="q12-3"/></label>
                                </div>
                                <div className="select-item">
                                    <label htmlFor="q12-4">02:00-5:00<input data-category="daytime" name="daytime"
                                                                            className="check-button daytime-check"
                                                                            onClick={event => props.checkButtonHandle(event)}
                                                                            type="checkbox" value="2:00-5:00"
                                                                            id="q12-4"/></label>
                                </div>
                                <div className="select-item">
                                    <label htmlFor="q12-5">5:00-7:00<input data-category="daytime" name="daytime"
                                                                           className="check-button daytime-check"
                                                                           onClick={event => props.checkButtonHandle(event)}
                                                                           type="checkbox" value="5:00-7:00"
                                                                           id="q12-5"/></label>
                                </div>
                                <div className="select-item">
                                    <label htmlFor="q12-6">7:00-9:00<input data-category="daytime" name="daytime"
                                                                           className="check-button daytime-check"
                                                                           onClick={event => props.checkButtonHandle(event)}
                                                                           type="checkbox" value="7:00-9:00"
                                                                           id="q12-6"/></label>
                                </div>
                            </div>
                            <div className="phone-confirm cred-item">
                                <i className="fa-solid fa-phone"></i><input required className="text-button"
                                                                            value={props.formData.phone}
                                                                            name="phone" type="text"
                                                                            onChange={event => props.handleFormInputs(event)}
                                                                            placeholder="Enter your phone number"/>
                            </div>
                            <button type="submit" className="phone-input" id="phone-submit"><i
                                className="fa-solid fa-calendar-days"></i>Confirm
                            </button>
                        </div>
                    </Form>

                </div>
                <div className="calendar-modal email-form-modal" style={{
                    opacity: (props.modal.modal && props.modal.type === "report") ? 1 : 0,
                    transition: '.3s',
                    visibility: (props.modal.modal && props.modal.type === "report") ? 'visible' : 'hidden'
                }}>
                    <Form className="" onSubmit={event => handleReportSubmit(event)}  style={{padding: '0 !important'}}>
                        <div className="calendar-wrapper" onClick={props.handleModal}>

                        </div>
                        <div className="calendar-container select-container"
                             style={{gap: "18px", display: "flex", flexDirection: "column"}}>
                            <div className="calendar-intro d-flex justify-content-between">
                                Please fill in the form to get personalized quotation<i id="close-modal"
                                                                                     className="fa-solid fa-xmark"></i>
                            </div>
                            <div className="cred-item">
                                <i className="ri-user-line"></i><input
                                onChange={event => props.handleFormInputs(event)}
                                className="radio-button text-button" required
                                autoComplete="off"
                                data-category="firstName" name="firstName" type="text"
                                value={props.formData.firstName}
                                id="q11-1" placeholder="First name"/>
                            </div>
                            <div className="cred-item">
                                <i className="ri-user-settings-line"></i><input
                                onChange={event => props.handleFormInputs(event)}
                                className="radio-button text-button" required
                                autoComplete="off"
                                data-category="lastName" name="lastName" type="text"
                                value={props.formData.lastName}
                                id="q11-2" placeholder="Last name"/>
                            </div>
                            <div className="cred-item ">
                                <i className="ri-mail-line"></i><input
                                onChange={event => props.handleFormInputs(event)}
                                className="radio-button text-button" required
                                autoComplete="off"
                                data-category="email" name="email" type="email"
                                value={props.formData.email}
                                id="q11-3" placeholder="Email"/>
                            </div>
                            <button type="submit" className="phone-input" id="phone-submit"><i
                                className="fa-solid fa-calendar-days"></i>Confirm
                            </button>
                        </div>

                    </Form>

                </div>
            </section>
        </>
    );
}

export default FormEstimateComplete;