import React, {useEffect, useRef, useState} from 'react';
import {Form} from "react-router-dom";
import '../../assets/css/contact-form.css'
import {
    APIProvider,
    useMapsLibrary
} from '@vis.gl/react-google-maps';
import {Helmet, HelmetProvider} from "react-helmet-async";

const ContactForm = (props) => {
    const [formData, setFormData] = React.useState({})
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [error, setError] = useState(null);
    const handleInput = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let {email, firstName, lastName, phone, type} = formData
        if (!email || !firstName || !lastName || !phone || !type) {
            setError('Please fill all the required fields.')
        }else{
            let data = {
                email,
                firstName,
                lastName,
                phone,
                type,
                address: selectedPlace.formatted_address,
                source: document.getElementById('source').value,
                user_id: props.bd.user_id,
                u_br: props.bd.u_br,
                u_os: props.bd.u_os,
            }
            fetch(`${props.debugValue}/api/contact-form`, {
                method: 'POST',
                body: JSON.stringify({formData: data}),
                headers: {
                    'Authorization': 'Bearer ' + props.debugValue.t,
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(data => {
                if (data.status === 200){
                    props.handleForms('contact', true)
                }else {
                    setError('Something went wrong! Please refresh the page and try again.')
                }
            }).catch(err=>alert("Something went wrong! Please try again."))

        }
    }

    const PlaceAutocomplete = ({onPlaceSelect}) => {
        const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
        const inputRef = useRef(null);
        const places = useMapsLibrary("places");

        useEffect(() => {
            if (!places || !inputRef.current) return;

            const options = {
                fields: ["geometry", "name", "formatted_address"],
            };

            setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
        }, [places]);
        useEffect(() => {
            if (!placeAutocomplete) return;

            placeAutocomplete.addListener("place_changed", () => {
                onPlaceSelect(placeAutocomplete.getPlace());
            });
        }, [onPlaceSelect, placeAutocomplete]);
        if (document.getElementById("address_input") && selectedPlace){
            document.getElementById("address_input").value = selectedPlace.formatted_address;
        }
        return (
            <div className="autocomplete-container_s">
                <input type="text" required name='address' id="address_input" placeholder="Address" ref={inputRef} />
            </div>
        );
    };

    const API_KEY = process.env.REACT_APP_GKEY ?? "YOUR_API_KEY";

    return (
        <div>

            <Form className={"form__holder"} onSubmit={event => handleSubmit(event)}>
                <b className="montserrat-form form__title">Home solar systems starting at</b>
                <p className="montserrat-form form__sub"><b> $39.8 </b>/month</p>
                <p className="montserrat-form form__desc">Enjoy top savings on our premier Solar Panels &
                    Micro inverters</p>
                <div className="entry__container">
                    <input type="hidden" value={'contact'} name={'source'} id={'source'}/>
                    <input type="text" name='firstName' id="first_name" required placeholder="Full name"
                           onChange={event => handleInput(event)}/>
                    <input type="text" name='lastName' id="last_name" required placeholder="Last name"
                           autoComplete="off" onChange={event => handleInput(event)}/>
                    <input type="email" name='email' id="email" required placeholder="Email" autoComplete="off"
                           onChange={event => handleInput(event)}/>
                    <input type="text" name='phone' id="phone" required placeholder="Phone number"
                           autoComplete="off"
                           onChange={event => handleInput(event)}/><APIProvider
                    apiKey={API_KEY}
                    solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
                >
                    <PlaceAutocomplete
                           autoComplete="off" onPlaceSelect={setSelectedPlace} value={selectedPlace}
                           // onChange={event => handleInput(event)}
                    />
                </APIProvider>


                    <select name="type" id="type" required
                            onChange={event => handleInput(event)} defaultValue={""}>
                    <option value="" disabled>Select the topic</option>
                        <option value="Residential">Residential solar energy</option>
                        <option value="Commercial">Commercial solutions</option>
                        <option value="Led">LED lighting</option>
                        <option value="Roof">Roof replacement</option>
                    </select>

                    <textarea name="comment" id="comment"
                              placeholder="Tells us more about what you are interested in" cols="30" rows="5"
                              autoComplete="off" onChange={event => handleInput(event)}></textarea>
                </div>
                {error &&
                    <p style={{fontSize: '12px', color: 'red'}}>{error}</p>
                }

                <p>
                <button type="submit" className="g-recaptcha" style={{width:'100%'}}
                            data-sitekey="6LfYUwIqAAAAANjkT-HvIF5P-XxjMwfMvul_ffJD"
                            data-callback='onSubmit'
                            data-action='submit'>Get a value
                    </button>
                </p>
                <span className="montserrat-form form__desc">Your personal information is never distributed or sold to anyone. You can opt-out of communications at any time. View our<a
                    className="privacy__link" href=""> privacy policy</a>.</span>
            </Form>
        </div>
    )
}

export default ContactForm;